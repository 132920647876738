import * as React from "react";
const SvgStatusConfirmed = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11m0-3.017A7.967 7.967 0 0 1 3.017 11 7.976 7.976 0 0 1 11 3.006 7.976 7.976 0 0 1 18.983 11 7.967 7.967 0 0 1 11 18.983m-1.067-3.082c.446 0 .86-.229 1.11-.62l4.346-6.644c.174-.262.272-.545.272-.795 0-.643-.566-1.111-1.198-1.111-.413 0-.762.218-1.034.664L9.91 13.069l-1.612-1.95c-.261-.315-.545-.468-.915-.468-.642 0-1.176.512-1.176 1.166 0 .294.098.544.338.838l2.232 2.647c.327.381.686.599 1.155.599"
    />
  </svg>
);
export default SvgStatusConfirmed;
