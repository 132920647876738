import * as React from "react";
const SvgStatusPartiallyConfirmed = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11m0-3.017A7.967 7.967 0 0 1 3.017 11 7.976 7.976 0 0 1 11 3.006 7.976 7.976 0 0 1 18.983 11 7.967 7.967 0 0 1 11 18.983m.01-4.661a3.35 3.35 0 0 0 3.344-3.344c0-1.83-1.513-3.322-3.343-3.322-1.84 0-3.344 1.493-3.344 3.322a3.344 3.344 0 0 0 3.344 3.344"
    />
  </svg>
);
export default SvgStatusPartiallyConfirmed;
