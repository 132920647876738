import { FC, ReactNode, useRef, useEffect } from "react";
import Portal from "components/Portal";
import { motion } from "framer-motion";
import useClickOutside from 'hooks/useClickOutside';
import { CloseX } from "components/Icons";
import "./Modal.scss";

type ModalProps = {
  children: ReactNode;
  closeButton?: boolean;
  clickOutsideToClose?: boolean;
  escToClose?: boolean;
  styles?: React.CSSProperties;
  onClose?: () => void;
  initialPosition?: { left: number, top: number };
  onAnimationComplete?: () => void;
};

const Modal: FC<ModalProps> = ({
  children,
  closeButton = false,
  clickOutsideToClose = false,
  escToClose = false,
  styles,
  onClose = () => {},
  initialPosition = { left: window.innerWidth / 2, top: window.innerHeight / 2 },
  onAnimationComplete = () => {}
}) => {
  const modalRef = useRef(null);

  useClickOutside(modalRef, onClose, undefined, clickOutsideToClose);

  useEffect(() => {
    if (escToClose) {
      const handleEscape = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
          console.log('ESC key pressed');
          onClose();
        }
      };

      document.addEventListener('keydown', handleEscape);
      return () => {
        document.removeEventListener('keydown', handleEscape);
      };
    }
  }, [onClose, escToClose]);

  if (!children) return null;
  if (!initialPosition) {
    initialPosition = {
      left: (window.innerWidth / 2), 
      top: (window.innerHeight / 2) 
    };
  }
  return ( 
    <Portal>
      <motion.div className="modal-mask"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.12 }}
      >
        <motion.div
          ref={modalRef}
          className="modal"
          style={styles}
          initial={{
            opacity: 1,
            top: initialPosition.top,
            left: initialPosition.left,
            translateX: "-50%",
            translateY: "-50%",
            scale: 0.8,
          }}
          animate={{
            opacity: 1,
            top: "50vh",
            left: "50vw",
            scale: 1,
            translateX: "-50%",
            translateY: "-50%"
          }}
          exit={{
            opacity: 0,
            scale: 0.8
          }}
          transition={{ duration: 0.12 }}
          onAnimationComplete={onAnimationComplete}
        >
          {closeButton && (
            <button
              className="close-x"
              onClick={onClose}
            >
              <span className="icon"><CloseX /></span>
            </button>
          )}
          {children}
        </motion.div>
      </motion.div>
    </Portal>
  );
};

export default Modal;