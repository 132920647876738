const jobHighlights: { id: string; name: string }[] = [
  { id: "1", name: "High Priority / Important" },
  { id: "2", name: "Extra Lg Job (5+ people, whole day)" },
  { id: "3", name: "Large Job (4-5 people, whole day)" },
  { id: "4", name: "Medium Job (2-3 people, up to whole day)" },
  { id: "5", name: "Small/Med Job (1-2 people, partial day)" },
  { id: "6", name: "Small Job (1 person, partial day)" },
];

export default jobHighlights;
