const schedulesData: {
  id: string; 
  title: string; 
  date_created: string 
}[] = [
  {
    id: "6",
    title: "Scheduling 3/11-14",
    date_created: "2024-03-06T00:00:00Z",
  },
  {
    id: "5",
    title: "Scheduling 3/4-7",
    date_created: "2024-02-27T00:00:00Z",
  },
  {
    id: "4",
    title: "Scheduling 2/26-29",
    date_created: "2024-02-21T00:00:00Z",
  },
  {
    id: "3",
    title: "Scheduling 2/19-22",
    date_created: "2024-02-14T00:00:00Z",
  },
  {
    id: "2",
    title: "Scheduling 2/12-15",
    date_created: "2024-02-07T00:00:00Z",
  },
  {
    id: "1",
    title: "Scheduling 2/5-8",
    date_created: "2024-01-31T00:00:00Z",
  },
];

export default schedulesData;
