import React, { FC, useState, useEffect } from 'react';
import EventTypeSelectMenu, { EventTypeType } from "components/SelectMenu/EventTypeSelectMenu";
import TeamMemberSelectMenu from "components/SelectMenu/TeamMemberSelectMenu";
import TimeRangeSelectMenu from "components/SelectMenu/TimeRangeSelectMenu";
import RecurrenceSelectMenu, { RecurrenceType } from "components/SelectMenu/RecurrenceSelectMenu";
import MonthlyRecurrenceSelectMenu, { MonthlyRecurrenceType } from "components/SelectMenu/MonthlyRecurrenceSelectMenu";
import MonthlyRecurrenceDetailSelector, { MonthlyDateType, MonthlyWeekType, MonthlyDayOfWeekType } from "components/SelectMenu/MonthlyRecurrenceDetailSelector";
import DateRangeSelector from 'components/DateRangeSelector';
import './EventEditor.scss';
import SlideSwitch from 'components/SlideSwitch';

type MonthlyRecurrenceDataType = {
  type?: MonthlyRecurrenceType;
  monthlyDate?: MonthlyDateType;
  monthlyWeek?: MonthlyWeekType;
  monthlyDayOfWeek?: MonthlyDayOfWeekType;
};

interface EventEditorProps {
  formConfig?: string[];
  defaultValues?: {
    teamMember?: string;
    eventType?: EventTypeType;
    recurrence?: RecurrenceType; // "Every Week" | "Every Other Week" | "Every Month"
    monthlyRecurrence?: MonthlyRecurrenceDataType;
    startDate?: Date;
    endDate?: Date;
    allDay?: boolean;
    startTime?: string;
    endTime?: string;
  };
  onChange?: (data: any) => void;
}

///////////////////////////////////////////
// The EventEditor component
const EventEditor: FC<EventEditorProps> = ({
  formConfig=[
    'teamMember',
    'eventType',
    'dateRange',
    'allDaySwitch',
    'timeRange',
    'recurrence',
    'monthlyRecurrence',
  ],
  defaultValues,
  onChange 
}) => {

  const [eventType, setEventType] = useState<EventTypeType | undefined>(defaultValues?.eventType);
  const handleEventType = (eventType: EventTypeType) => {
    setEventType(eventType);
  };

  const [recurrence, setRecurrence] = useState<RecurrenceType | undefined>(defaultValues?.recurrence);
  const handleRecurrence = (recurrence: RecurrenceType) => {
    if (!recurrence) resetMonthlyRecurrence();
    setRecurrence(recurrence);
  };

  const [monthlyRecurrence, setMonthlyRecurrence] = useState<MonthlyRecurrenceDataType | undefined>(defaultValues?.monthlyRecurrence);
  const [monthlyRecurrenceType, setMonthlyRecurrenceType] = useState<MonthlyRecurrenceType | undefined>(defaultValues?.monthlyRecurrence?.type);
  const [monthlyRecurrenceDate, setMonthlyRecurrenceDate] = useState<MonthlyDateType | undefined>(defaultValues?.monthlyRecurrence?.monthlyDate);
  const [monthlyRecurrenceWeek, setMonthlyRecurrenceWeek] = useState<MonthlyWeekType | undefined>(defaultValues?.monthlyRecurrence?.monthlyWeek);
  const [monthlyRecurrenceDayOfWeek, setMonthlyRecurrenceDayOfWeek] = useState<MonthlyDayOfWeekType | undefined>(defaultValues?.monthlyRecurrence?.monthlyDayOfWeek);

  const handleMonthlyRecurrenceType = (selectionOption:any) => {
    if (selectionOption === null) {
      resetMonthlyRecurrence();
      return;
    }
    setMonthlyRecurrenceType(selectionOption?.value);
  };

  useEffect(() => {
    console.log('monthlyRecurrenceType: ', monthlyRecurrenceType);
    
    if (!monthlyRecurrenceType) {
      setMonthlyRecurrence(undefined);
      return;
    } else {
      let monthlyRecurrenceData:MonthlyRecurrenceDataType = {
        type: monthlyRecurrenceType
      };
      if (monthlyRecurrenceType === "Exact Date") {
        console.log('monthlyRecurrenceDate: ', monthlyRecurrenceDate);
        monthlyRecurrenceData = {...monthlyRecurrenceData, monthlyDate: monthlyRecurrenceDate};
      }
      if (monthlyRecurrenceType === "Week & Day") {
        monthlyRecurrenceData = {...monthlyRecurrenceData, monthlyWeek: monthlyRecurrenceWeek, monthlyDayOfWeek: monthlyRecurrenceDayOfWeek};
      }
      setMonthlyRecurrence(monthlyRecurrenceData);
    }
  }, [
    monthlyRecurrenceType,
    monthlyRecurrenceDate,
    monthlyRecurrenceWeek,
    monthlyRecurrenceDayOfWeek
  ]);

  const resetMonthlyRecurrence = () => {
    setMonthlyRecurrenceType(undefined);
    setMonthlyRecurrenceDate(undefined);
    setMonthlyRecurrenceWeek(undefined);
    setMonthlyRecurrenceDayOfWeek(undefined);
  };

  const [startDate, setStartDate] = useState<Date | null>(defaultValues?.startDate || null);
  const [endDate, setEndDate] = useState<Date | null>(defaultValues?.endDate || null);

  const [startTime, setStartTime] = useState<string | undefined>(defaultValues?.startTime);
  const [endTime, setEndTime] = useState<string | undefined>(defaultValues?.endTime);
  const handleTimeRange = (startTime: string | undefined, endTime: string | undefined) => {
    setStartTime(startTime);
    setEndTime(endTime);
  };

  const [allDay, setAllDay] = useState<boolean | undefined>(defaultValues?.allDay || false);
  const handleAllDay = (allDay: boolean) => { 
    setAllDay(allDay);
    if (allDay === true) {
      setStartTime(undefined);
      setEndTime(undefined);
    }
  };

  // UseEffects
  useEffect(() => {
    setAllDay(defaultValues?.allDay);
  }, [defaultValues?.allDay]);

  const [teamMember, setTeamMember] = useState<string | undefined>(defaultValues?.teamMember);
  const handleTeamMember = (teamMember: string) => {
    setTeamMember(teamMember);
  };

  useEffect(() => {
    let returnData = {};
    if (teamMember) returnData = {...returnData, teamMember};
    if (eventType) returnData = {...returnData, eventType};
    if (startDate) returnData = {...returnData, startDate};
    if (endDate) returnData = {...returnData, endDate};
    if (allDay) returnData = {...returnData, allDay};
    if (startTime) returnData = {...returnData, startTime};
    if (endTime) returnData = {...returnData, endTime};
    if (recurrence) returnData = {...returnData, recurrence};
    if (monthlyRecurrence && recurrence === "Every Month") returnData = {...returnData, monthlyRecurrence};

    onChange && onChange(returnData);
  }, [
    teamMember,
    eventType,
    startDate,
    endDate,
    allDay,
    startTime,
    endTime,
    recurrence,
    monthlyRecurrence,
  ]);

  const formControlRenderers: { [key: string]: () => JSX.Element | null } = {
    teamMember: () => (
      <TeamMemberSelectMenu
        selected={teamMember}
        onChange={(selected) => handleTeamMember(selected?.value)}
      />
    ),
    eventType: () => (
      <EventTypeSelectMenu
        selected={eventType}
        onChange={(selected) => handleEventType(selected?.value)}
      />
    ),
    dateRange: () => (
      <DateRangeSelector
        startDate={startDate}
        endDate={endDate}
        onChange={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
      />
    ),
    allDaySwitch: () => (
      <SlideSwitch
        isOn={allDay}
        labelText="All Day"
        onToggle={handleAllDay}
      />
    ),
    timeRange: () =>
      allDay === false ? (
        <TimeRangeSelectMenu
          startTime={startTime}
          endTime={endTime}
          onChange={handleTimeRange}
          interval={30}
        />
      ) : null,
    recurrence: () => (
      <RecurrenceSelectMenu
        selected={recurrence}
        onChange={(selected) => handleRecurrence(selected?.value)}
      />
    ),
    monthlyRecurrence: () =>
      recurrence === "Every Month" ? (
        <>
          <MonthlyRecurrenceSelectMenu
            selected={monthlyRecurrenceType}
            onChange={handleMonthlyRecurrenceType}
          />
          <MonthlyRecurrenceDetailSelector
            monthlyRecurrenceType={monthlyRecurrenceType}
            monthlyDate={monthlyRecurrenceDate}
            monthlyWeek={monthlyRecurrenceWeek}
            monthlyDayOfWeek={monthlyRecurrenceDayOfWeek}
            // onChange={handleMonthlyRecurrenceDetailChange}
            onMonthlyDateChange={(selectedOption) => setMonthlyRecurrenceDate(selectedOption?.value)}
            onMonthlyWeekChange={(selectedOption) => setMonthlyRecurrenceWeek(selectedOption?.value)}
            onMonthlyDayOfWeekChange={(selectedOption) => setMonthlyRecurrenceDayOfWeek(selectedOption?.value)}
          />
        </>
      ) : null,
  };

  return (
    <div className="event-editor">
      {formConfig?.map((configItem, index) => {
        const renderFunction = formControlRenderers[configItem];
        return renderFunction ? <React.Fragment key={index}>{renderFunction()}</React.Fragment> : null;
      })}
    </div>
  )
};

export default EventEditor;