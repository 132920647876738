import { TeamMember } from "types";

// GraphQL query used to fetch data
/*
query GetActiveUsers {
  users(filter:{status:ACTIVATED}, first:40){
    edges {
      node {
        id
        name {
          first
          last
          full
        }
        email {
          raw
        }
        phone {
          friendly
        }
      }
    }
  }
}
*/

// // Raw response from the Jobber API query above
const jobberData = {
  "data": {
    "users": {
      "edges": [
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkz",
            "name": {
              "first": "Ben",
              "last": "Delaney",
              "full": "Ben Delaney"
            },
            "email": {
              "raw": "bendelaney@gmail.com"
            },
            "phone": {
              "friendly": "(509) 879-3344"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE2",
            "name": {
              "first": "Isaiah",
              "last": "Crandall",
              "full": "Isaiah Crandall"
            },
            "email": {
              "raw": "isaiahcrandall@rocketmail.com"
            },
            "phone": {
              "friendly": "(509) 413-9809"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkx",
            "name": {
              "first": "Kelly",
              "last": "Chadwick",
              "full": "Kelly Chadwick"
            },
            "email": {
              "raw": "k@spiritpruners.com"
            },
            "phone": {
              "friendly": "(***) ***-3062"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDU0NzYw",
            "name": {
              "first": "Krystn",
              "last": "Parmley",
              "full": "Krystn Parmley"
            },
            "email": {
              "raw": "Parmleyk@hotmail.com"
            },
            "phone": {
              "friendly": "(509) 385-8711"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTM2MDE3OA==",
            "name": {
              "first": "Sara",
              "last": "Callan Boggs",
              "full": "Sara Callan Boggs"
            },
            "email": {
              "raw": "admin@spiritpruners.com"
            },
            "phone": {
              "friendly": "(509) 808-6978"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNTI0NTc3",
            "name": {
              "first": "Alex",
              "last": "Moe",
              "full": "Alex Moe"
            },
            "email": {
              "raw": "alexstevenmoe@gmail.com"
            },
            "phone": {
              "friendly": "(509) 768-6215"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE4",
            "name": {
              "first": "Anthony",
              "last": "Morrow",
              "full": "Anthony Morrow"
            },
            "email": {
              "raw": "anthonymorrow044@gmail.com"
            },
            "phone": {
              "friendly": "(509) 768-6971"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNTM2MDYx",
            "name": {
              "first": "Dario",
              "last": "Ré",
              "full": "Dario Ré"
            },
            "email": {
              "raw": "dariore555@gmail.com"
            },
            "phone": {
              "friendly": "(509) 655-2044"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvOTExMjIy",
            "name": {
              "first": "Felix",
              "last": "Gayton",
              "full": "Felix Gayton"
            },
            "email": {
              "raw": "felix_gayton@yahoo.com"
            },
            "phone": {
              "friendly": "(208) 818-8180"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTY4NjUxMg==",
            "name": {
              "first": "Floater",
              "last": "",
              "full": "Floater"
            },
            "email": {
              "raw": "Admin+floater@spiritpruners.com"
            },
            "phone": {
              "friendly": ""
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
            "name": {
              "first": "Jose",
              "last": "Villa",
              "full": "Jose Villa"
            },
            "email": {
              "raw": "paint.much@yahoo.com"
            },
            "phone": {
              "friendly": "(509) 216-5507"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjAzMDkzMg==",
            "name": {
              "first": "Madeleine",
              "last": "Hooker",
              "full": "Madeleine Hooker"
            },
            "email": {
              "raw": "madeleinejhooker@gmail.com"
            },
            "phone": {
              "friendly": "(360) 739-6768"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTQ0MDA0MA==",
            "name": {
              "first": "Mason",
              "last": "McBride",
              "full": "Mason McBride"
            },
            "email": {
              "raw": "masonmcbride529@gmail.com"
            },
            "phone": {
              "friendly": "(509) 601-9298"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTgxMjY4OQ==",
            "name": {
              "first": "Omar",
              "last": "Sierra",
              "full": "Omar Sierra"
            },
            "email": {
              "raw": "ololiuqui@gmail.com"
            },
            "phone": {
              "friendly": "(509) 590-5358"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTg5NjI2OA==",
            "name": {
              "first": "Peter",
              "last": "Sherman",
              "full": "Peter Sherman"
            },
            "email": {
              "raw": "peter.w.sherman88@gmail.com"
            },
            "phone": {
              "friendly": "(360) 298-0865"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTQ4Njg3MA==",
            "name": {
              "first": "Pura",
              "last": "Vida 1",
              "full": "Pura Vida 1"
            },
            "email": {
              "raw": "admin+puravida1@spiritpruners.com"
            },
            "phone": {
              "friendly": ""
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTQ4Njg3MQ==",
            "name": {
              "first": "Pura",
              "last": "Vida 2",
              "full": "Pura Vida 2"
            },
            "email": {
              "raw": "admin+puravida2@spiritpruners.com"
            },
            "phone": {
              "friendly": ""
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjA0MjI2MA==",
            "name": {
              "first": "Pura",
              "last": "Vida 3",
              "full": "Pura Vida 3"
            },
            "email": {
              "raw": "admin+puravida3@spiritpruners.com"
            },
            "phone": {
              "friendly": ""
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDc1NDUx",
            "name": {
              "first": "Tal",
              "last": "Weisenburger",
              "full": "Tal Weisenburger"
            },
            "email": {
              "raw": "talwisen@yahoo.com"
            },
            "phone": {
              "friendly": "(509) 688-5110"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTEx",
            "name": {
              "first": "Wakan",
              "last": "Burrows",
              "full": "Wakan Burrows"
            },
            "email": {
              "raw": "wakanlovestrees@gmail.com"
            },
            "phone": {
              "friendly": "(509) 499-6941"
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNjAzMzI2",
            "name": {
              "first": "Zayren",
              "last": "Bubb",
              "full": "Zayren Bubb"
            },
            "email": {
              "raw": "bubbzayren24@gmail.com"
            },
            "phone": {
              "friendly": "(509) 991-1470"
            }
          }
        }
      ]
    }
  },
  "extensions": {
    "cost": {
      "requestedQueryCost": 402,
      "actualQueryCost": 212,
      "throttleStatus": {
        "maximumAvailable": 10000,
        "currentlyAvailable": 9788,
        "restoreRate": 500
      }
    },
    "versioning": {
      "version": "2024-04-17"
    }
  }
};

// Our mock data for the user list
const nonJobberData = [
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkz",
    "name": {
      "first": "Ben",
      "last": "Delaney",
      "full": "Ben Delaney"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "1"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE2",
    "name": {
      "first": "Isaiah",
      "last": "Crandall",
      "full": "Isaiah Crandall"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkx",
    "name": {
      "first": "Kelly",
      "last": "Chadwick",
      "full": "Kelly Chadwick"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDU0NzYw",
    "name": {
      "first": "Krystn",
      "last": "Parmley",
      "full": "Krystn Parmley"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTM2MDE3OA==",
    "name": {
      "first": "Sara",
      "last": "Callan Boggs",
      "full": "Sara Callan Boggs"
    },
    "imageUrl": "abc.jpg",
    "highlightId": ""
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNTI0NTc3",
    "name": {
      "first": "Amoe",
      "last": "Moe",
      "full": "Alex Moe"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "1"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE4",
    "name": {
      "first": "Anthony",
      "last": "Morrow",
      "full": "Anthony Morrow"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNTM2MDYx",
    "name": {
      "first": "Dario",
      "last": "Ré",
      "full": "Dario Ré"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvOTExMjIy",
    "name": {
      "first": "Felix",
      "last": "Gayton",
      "full": "Felix Gayton"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "2"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTY4NjUxMg==",
    "name": {
      "first": "Floater",
      "last": "",
      "full": "Floater"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "99"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
    "name": {
      "first": "Jose",
      "last": "Villa",
      "full": "Jose Villa"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "1"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjAzMDkzMg==",
    "name": {
      "first": "Madeleine",
      "last": "Hooker",
      "full": "Madeleine Hooker"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTQ0MDA0MA==",
    "name": {
      "first": "Mason",
      "last": "McBride",
      "full": "Mason McBride"
    },
    "imageUrl": "abc.jpg",
    "highlightId": ""
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTgxMjY4OQ==",
    "name": {
      "first": "Omar",
      "last": "Sierra",
      "full": "Omar Sierra"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTg5NjI2OA==",
    "name": {
      "first": "Peter",
      "last": "Sherman",
      "full": "Peter Sherman"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "3"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTQ4Njg3MA==",
    "name": {
      "first": "PV1",
      "last": "Vida 1",
      "full": "Pura Vida 1"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "5"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTQ4Njg3MQ==",
    "name": {
      "first": "PV2",
      "last": "Vida 2",
      "full": "Pura Vida 2"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "5"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjA0MjI2MA==",
    "name": {
      "first": "PV3",
      "last": "Vida 3",
      "full": "Pura Vida 3"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "5"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDc1NDUx",
    "name": {
      "first": "Tal",
      "last": "Weisenburger",
      "full": "Tal Weisenburger"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "2"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTEx",
    "name": {
      "first": "Wakan",
      "last": "Burrows",
      "full": "Wakan Burrows"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "1"
  },
  {
    "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNjAzMzI2",
    "name": {
      "first": "Zayren",
      "last": "Bubb",
      "full": "Zayren Bubb"
    },
    "imageUrl": "abc.jpg",
    "highlightId": "2"
  }
];

// This list will come from our App's SETTINGS, 
// where we can decide which users to exclude from the list
const excludeList = [
  {id: "Z2lkOi8vSm9iYmVyL1VzZXIvMTM2MDE3OA=="},
  {id: "Z2lkOi8vSm9iYmVyL1VzZXIvMTQ0MDA0MA=="}
];

const convertAndMergeData = (jobberData: any, nonJobberData: any): TeamMember[] => {
  return jobberData.data.users.edges
    .filter((edge: any) => !excludeList.find((m: any) => m.id === edge.node.id))
    .map((edge: any) => {
      const { id, name, email, phone } = edge.node;
      const nonJobber = nonJobberData.find((nj: any) => nj.id === id);
      return {
        id: id,
        firstName: nonJobber?.name?.first || name.first,
        lastName: nonJobber?.name?.last || name.last,
        email: email.raw,
        phone: phone.friendly,
        ...nonJobber
      };
    })
    .sort((a: any, b: any) => (a.highlightId > b.highlightId) ? 1 : -1);
};
const TeamMembersData: TeamMember[] = convertAndMergeData(jobberData, nonJobberData);

export default TeamMembersData;