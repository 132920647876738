import * as React from "react";
const SvgAngleDown = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g transform="translate(0, 10)" fill="currentColor">
          <path d="M22.0584407,28.7446998 C23.6854093,30.4184334 26.3276064,30.4184334 27.954575,28.7446998 L48.7797735,7.32091051 C50.4067422,5.64717697 50.4067422,2.9290337 48.7797735,1.25530016 C47.1528049,-0.418433385 44.5106078,-0.418433385 42.8836392,1.25530016 L25,19.6529792 L7.1163608,1.26869002 C5.48939216,-0.405043517 2.84719511,-0.405043517 1.22022647,1.26869002 C-0.406742158,2.94242357 -0.406742158,5.66056684 1.22022647,7.33430038 L22.045425,28.7580897 L22.0584407,28.7446998 Z" id="Path"></path>
      </g>
  </svg>
);
export default SvgAngleDown;


