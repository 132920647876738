import React, {FC, useState} from 'react';
import SelectMenu from './SelectMenu';
import TeamMembersData from 'data/teamMembersData';

const teamMemberOptions = TeamMembersData.map((teamMember) => ({
  value: teamMember.firstName + ' ' + teamMember.lastName,
  label: teamMember.firstName + ' ' + teamMember.lastName,
}));

interface TeamMemberSelectorProps {
  selected?: any;
  onChange: (selectedOption:any) => void;
  selectProps?: any;
}
  
const TeamMemberSelector: FC<TeamMemberSelectorProps> = ({
  selected, 
  onChange, 
  selectProps 
}) => {

  const selectedOption = selected ? teamMemberOptions.find(option => option.value === selected) || null : undefined;
  
  return (
    <SelectMenu 
      options={teamMemberOptions}
      value={selectedOption}
      selectedOption={selectedOption}
      placeholder="Team Member"
      isSearchable={true}
      onChange={onChange}
      {...selectProps}
    />
  );
};

export default TeamMemberSelector;