import React, { FC, useEffect, useRef, useState, useCallback } from "react";
import DataViewer from "components/DataViewer";
import './Sandbox.scss';
import styled from "styled-components";
import EventEditor from "./EventEditor";
import { DateTime } from "luxon";

// ==========================================================
const Container = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  gap: 20px;
`;

const currentDate = DateTime.now();
const endDate = currentDate.plus({days: 4});

const Sandbox = () => {
  const [eventEditorOutput, setEventEditorOutput] = useState({});
  
  return (
    <Container>
      <EventEditor
        formConfig={[
          'teamMember',
          'eventType',
          'dateRange',
          'allDaySwitch',
          'timeRange',
          'recurrence',
          'monthlyRecurrence',
        ]}
        defaultValues={{
          teamMember: "Ben Delaney",
          eventType: "Personal Appointment",
          recurrence: "Every Month",
          monthlyRecurrence: {
            type: "Week & Day",
            monthlyDate: "1st",
            monthlyWeek: "First",
            monthlyDayOfWeek: "Monday",
          },
          startDate: currentDate.toJSDate(),
          endDate: endDate.toJSDate(),
          allDay: false,
          startTime: "07:00:00",
          endTime: "13:00:00",
        }}
        onChange={(data) => {
          setEventEditorOutput(data);
        }}
      />
      <DataViewer data={eventEditorOutput} log={false}/>
    </Container>
  )
}
export default Sandbox;


