import React, { ForwardRefRenderFunction, useState, useEffect, useRef } from "react";
import useClickOutside from "hooks/useClickOutside";
import { JobVisitConfirmationStatus, StatusIconMapping } from "types";
import {
  StatusOpen,
  StatusConfirmed,
  StatusConfirmedActive,
  StatusPartiallyConfirmed,
  StatusPartiallyConfirmedActive,
  StatusPending,
  StatusPendingActive,
  StatusImportant,
  StatusImportantActive,
} from "components/Icons";
import "./JobVisitConfirmationStatusSelector.scss";

export const statuses: JobVisitConfirmationStatus[] = [
    "Unconfirmed",
    "Confirmed",
    "Confirmation Pending",
    "Partially Confirmed",
    "Important",
  ];
  
// Define a mapping from status to icon
export const statusIcons: StatusIconMapping = {
  "Unconfirmed": <StatusOpen />,
  "Confirmed": <StatusConfirmed />,
  "Confirmation Pending": <StatusPending />,
  "Partially Confirmed": <StatusPartiallyConfirmed />,
  "Important": <StatusImportant />,
};
export const statusIconsActive: StatusIconMapping = {
  "Unconfirmed": <StatusOpen />,
  "Confirmed": <StatusConfirmedActive />,
  "Confirmation Pending": <StatusPendingActive />,
  "Partially Confirmed": <StatusPartiallyConfirmedActive />,
  "Important": <StatusImportantActive />,
};

// Define the props of JobVisitConfirmationStatusSelector component
export interface JobVisitConfirmationStatusSelectorProps {
  status?: JobVisitConfirmationStatus;
  hideText?: boolean;
  menuVisible?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  onSelect: (status: JobVisitConfirmationStatus) => void;
}

const JobVisitConfirmationStatusSelector: ForwardRefRenderFunction<HTMLDivElement, JobVisitConfirmationStatusSelectorProps> = (
  { status, hideText, menuVisible, onOpen, onClose, onSelect },
  ref
) => {
  const menuRef = useRef(null);
  const toggleRef = useRef(null);
  const [confirmationStatus, setConfirmationStatus] = useState((status || "Open") as JobVisitConfirmationStatus);
  const [showMenu, setShowMenu] = useState(false);

  useClickOutside(menuRef, () => setShowMenu(false), toggleRef);

  useEffect(() => {
    if (showMenu && onOpen) {
      onOpen();
    } else if (!showMenu && onClose) {
      onClose();
    }
  }, [showMenu, onOpen, onClose]);

  useEffect(() => {
    setShowMenu(menuVisible || false);
  }, [menuVisible]);

  const handleSelect = (status: JobVisitConfirmationStatus) => {
    setConfirmationStatus(status);
    setShowMenu(false);
    onSelect(status);
  };

  return (
    <div ref={ref} className={`jobvisit-confirmation-status-selector ${hideText ? 'no-text':''}`}>
      <button ref={toggleRef} className="toggle" onClick={() => setShowMenu(!showMenu)} >
        <span className="icon">{statusIconsActive[confirmationStatus]}</span>
        {!hideText && <span className="text">{confirmationStatus}</span>}
      </button>
      {showMenu && (
        <ul ref={menuRef} className="menu">
          {statuses.map((status) => (
            <li key={status} onClick={() => handleSelect(status)}>
              <span className="icon">{statusIcons[status]}</span>
              <span className="text">{status}</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default React.forwardRef(JobVisitConfirmationStatusSelector);