import * as React from "react";
const SvgStatusPartiallyConfirmedActive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="nonzero">
      <path
        fill="currentColor"
        d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11"
      />
      <path
        fill="#FFF"
        d="M11.01 14.322a3.35 3.35 0 0 0 3.344-3.344c0-1.83-1.513-3.322-3.343-3.322-1.84 0-3.344 1.493-3.344 3.322a3.344 3.344 0 0 0 3.344 3.344"
      />
    </g>
  </svg>
);
export default SvgStatusPartiallyConfirmedActive;
