import * as React from "react";
const SvgStatusConfirmedActive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <path
      fill="#0087FF"
      fillRule="nonzero"
      d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11m-1.133-5.794c-.49 0-.882-.229-1.22-.643l-2.374-2.799c-.261-.305-.36-.577-.36-.904 0-.686.567-1.23 1.253-1.23.392 0 .697.163.98.49l1.71 2.058 3.889-6.164c.294-.468.653-.708 1.1-.708.675 0 1.274.49 1.274 1.187 0 .261-.098.556-.294.839l-4.76 7.21c-.294.413-.718.664-1.198.664"
    />
  </svg>
);
export default SvgStatusConfirmedActive;
