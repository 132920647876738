import { useUserContext } from "../../contexts";
import { Navigate, Route, Routes as ReactRouterRoutes } from "react-router-dom";
import Auth from "pages/Auth";
import AppFrame from "components/AppFrame";
import ScheduleDocument from "components/ScheduleDocument";
import NewScheduleForm from "components/ScheduleDocument";
import Settings from "components/Settings";
import Sandbox from "pages/Sandbox/Sandbox";

const Routes = () => {
  return (
    <ReactRouterRoutes>
      <Route path="/" element={<AppFrame />}>
        {/* <JobProvider> */}
        <Route index element={<Navigate to="/schedule/default" />} />
        <Route path="schedule/:scheduleId" element={<ScheduleDocument />} />
        <Route path="schedule/new" element={<NewScheduleForm />} />
        <Route path="settings" element={<Settings />} />
        <Route path="auth" element={<Auth />} />
        {/* </JobProvider> */}
      </Route>
      <Route path="sandbox" element={<Sandbox />} />
    </ReactRouterRoutes>
  );
};

export default Routes;
