import React, { useContext, useState } from "react";
import {
  SidebarClosed,
  SidebarOpen,
  SplitFrame,
  SplitFrameActive,
  SyncWithJobber,
} from "components/Icons";
import "./TopBar.scss";

interface TopBarProps {
  isSidebarOpen: boolean;
  isSplitFrameActive: boolean;
  toggleSidebar: () => void;
}

const TopBar: React.FC<TopBarProps> = (props) => {
  // const { syncWithJobber } = useContext(JobContext)
  return (
    <div className="top-bar">
      <button
        className="sidebar-toggle"
        onClick={props.toggleSidebar}
      >
        {props.isSidebarOpen ? <SidebarOpen /> : <SidebarClosed />}
      </button>
      {/* TODO: SPLIT VIEW! */}
      {/* <button
        className="split-view-toggle"
        onClick={() => {
          alert("Split Frame coming soon 😎");
        }}
      >
        {props.isSplitFrameActive ? <SplitFrameActive /> : <SplitFrame />}
      </button> */}
      {/* <div className="title">SchedulePad</div> */}
      <button
        className="sync-with-jobber"
        onClick={() => {
          alert("Sync with Jobber");
        }}
      >
        <SyncWithJobber />
      </button>
    </div>
  );
};

export default TopBar;
