import { JobVisit, ScheduleDay, ScheduleDocument } from 'types';
import APP_SETTINGS from 'data/appSettings';

// //////////////////////////////////////////////////////////
// This is the GraphQL query that is used to get the Job Visits for a specific date range.
/*
query VisitsByDateRange_Detailed {
  visits(
    filter: {
      startAt: {
        after: "2024-05-19T00:00:00",
        before: "2024-05-23T23:59:00"
      }
      status:ACTIVE
    }
    sort: {
      key:START_AT
      direction: ASCENDING
    }
    timezone: "America/Los_Angeles"
  ) {
    edges {
      node {
        id
        title
        startAt
        endAt
        instructions
        assignedUsers(first:20) {
          nodes {
            id
            name {
              full
            }
          }
        }
        client {
          id
          firstName
          lastName
          emails {
            address
            primary
          }
          phones {
            number
            primary
            smsAllowed
          }
          companyName
        }
        property {
          id
          address {
            street
            city
            province
            postalCode
            coordinates {
              latitudeString
              longitudeString
            }
          }
        }          
        job {
          id
          jobberWebUri
          jobNumber
          total
          customFields {
            __typename
            ... on CustomFieldText {
              label
              valueText
            }
          }
        }
      }
    }
  }
}
*/

// This is the raw JSON data that is returned from the query, 
// passing in the date range of March 4th, 2024 to March 7th, 2024.:
const visitsDATA = {
  "data": {
    "visits": {
      "edges": [
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MDI0NDQ5NzU=",
            "title": "√=Schiermeister -V- 11123 E Ponderosa Dr - 1C*/1P 1d",
            "startAt": "2024-05-20T14:30:00Z",
            "endAt": "2024-05-20T23:00:00Z",
            "instructions": "🚚Peter, later \r\nSHORT SHEARS\r\nAnnual visit: \nShear/smoothing of o.grape on west side\nHouse clearance on conifer shrub near front door\nCouple cuts on mugo - leaning on fence\nBack hillside:\r\nShearing of burning bushes, boxwoods.\r\nApple on hillside in back - somewhat more thorough tuneup - open up space, more of a fruit prune style. Not more than 2h.\r\nMinor tuneup on maples - mainly clearance and obvious hangers or dead.\r\nSee photos for specific items. Call Ben with questions.\r\n\r\nBD",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvOTExMjIy",
                  "name": {
                    "full": "Felix Gayton"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTg5NjI2OA==",
                  "name": {
                    "full": "Peter Sherman"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDc1NDUx",
                  "name": {
                    "full": "Tal Weisenburger"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8xODg1Mzg1OA==",
              "firstName": "Scott",
              "lastName": "Schiermeister",
              "emails": [
                {
                  "address": "sschiermeister@hotmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 993-3564",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzE5OTA5OTE5",
              "address": {
                "street": "11123 E Ponderosa Dr",
                "city": "Spokane Valley",
                "province": "WA",
                "postalCode": "99206",
                "coordinates": {
                  "latitudeString": "47.6066699",
                  "longitudeString": "-117.2534018"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi8yMDEzOTY2MA==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/20139660",
              "jobNumber": 640,
              "total": 1700,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Ben"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MDI1MDg4OTU=",
            "title": "√=Rizzuto -VS- 3808 S Thierman Ln - 2P 3h",
            "startAt": "2024-05-20T14:30:00Z",
            "endAt": "2024-05-20T18:00:00Z",
            "instructions": "🛻Jose+Small Trailer (OR could take 🚒Red Truck+Dump Trailer??)\r\n\r\nAnnual Visit\r\ntune up of 4 fruit trees, maybe work on front maples bottom. Check Lindens, lilac, plum, etc. ",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
                  "name": {
                    "full": "Jose Villa"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjAzMDkzMg==",
                  "name": {
                    "full": "Madeleine Hooker"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8xODg1Mzk4Mw==",
              "firstName": "Tony",
              "lastName": "Rizzuto",
              "emails": [
                {
                  "address": "trrizzuto62@gmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 939-3262",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzE5OTEwMDMz",
              "address": {
                "street": "3808 S. Thierman Lane",
                "city": "Spokane",
                "province": "WA",
                "postalCode": "99223",
                "coordinates": {
                  "latitudeString": "47.6193931",
                  "longitudeString": "-117.3153403"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi8xODQ0MTc0NQ==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/18441745",
              "jobNumber": 437,
              "total": 0,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzEzOTg3Mzc0NDY=",
            "title": "⚠️🔩Whitson -V- 4415 N Bessie Rd - 1C/1G 1d, 1B 0.5d",
            "startAt": "2024-05-20T15:00:00Z",
            "endAt": "2024-05-20T23:30:00Z",
            "instructions": "🚚Peter\r\n🛻Zayren: 10 PLYWOOD, BOLTING KIT\r\n(3) 3/4\" x  36\" \r\nNUTS & WASHERS\r\nBring large Forstner bit please.\r\n\r\nHoney locust. Large reductions as indicated in photos to reduce weight on both of the two stems. \r\nPruning to remove deadwood and broken or damaged branches. \r\n1C/1G 1d\r\n\r\nInstallation of three 3/4” bolts to support large crack in primary stems. \r\nPerformed after reductions have been made. Attempt to cinch together as much as possible prior to bolting. \r\n1B 4h\r\n\r\nBD",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTg5NjI2OA==",
                  "name": {
                    "full": "Peter Sherman"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNjAzMzI2",
                  "name": {
                    "full": "Zayren Bubb"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkz",
                  "name": {
                    "full": "Ben Delaney"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC84MzQ2MDQwMQ==",
              "firstName": "Stephanie & Chip",
              "lastName": "Whitson",
              "emails": [
                {
                  "address": "stephie83815@yahoo.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "208-771-0097 Stephanie ",
                  "primary": false,
                  "smsAllowed": true
                },
                {
                  "number": "208-651-5960 Chip",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzkwMDg0MDg1",
              "address": {
                "street": "4415 N Bessie Rd",
                "city": "Spokane",
                "province": "WA",
                "postalCode": "99212",
                "coordinates": {
                  "latitudeString": "47.6973812",
                  "longitudeString": "-117.2909094"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84ODk4NzEyOA==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/88987128",
              "jobNumber": 4328,
              "total": 2350,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": "Honey Locust - prune and bolt"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Ben"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MDYyODgxNDc=",
            "title": "Fougere -SE- 1823 S Mt Vernon - 1P 6h",
            "startAt": "2024-05-20T15:00:00Z",
            "endAt": "2024-05-20T22:00:00Z",
            "instructions": "Omar: TWINE\r\nJosé🚒+DT, later\r\nPETER DROP OFF in AM: 12' LADDER\r\n\r\nFrom client: “Please note there are new tree peonies in the soil on the east side of the stand of arbor vitae if you could avoid them I'd appreciate it.”\r\n\r\n\r\nTrim and tie-up Arborvitae hedges to control size and re-establish shape. Not full shearing\r\n\r\nBD",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
                  "name": {
                    "full": "Jose Villa"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTgxMjY4OQ==",
                  "name": {
                    "full": "Omar Sierra"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTg5NjI2OA==",
                  "name": {
                    "full": "Peter Sherman"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8yMzk2MTY4OQ==",
              "firstName": "Merry",
              "lastName": "Fougere",
              "emails": [
                {
                  "address": "merrieux@mac.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 251-2161",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzI2NTUzOTM2",
              "address": {
                "street": "1823 South Mount Vernon Street",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99223",
                "coordinates": {
                  "latitudeString": "47.6386723",
                  "longitudeString": "-117.37041569999997"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84OTQ4Nzk2MA==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/89487960",
              "jobNumber": 4350,
              "total": 765,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Ben"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": "returning client"
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MTIxNjM0MTk=",
            "title": "Swan -C- 2105 W Falls Ave - 1P 6hr NC",
            "startAt": "2024-05-20T15:00:00Z",
            "endAt": "2024-05-20T19:00:00Z",
            "instructions": "Prune other apple like first one!\r\nKelly",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDU0NzYw",
                  "name": {
                    "full": "Krystn Parmley"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8xODg1Mzg5Mw==",
              "firstName": "Stephanie",
              "lastName": "Swan",
              "emails": [
                {
                  "address": "s.swan@comcast.net",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 290-0357",
                  "primary": true,
                  "smsAllowed": true
                },
                {
                  "number": "(509) 325-1722",
                  "primary": false,
                  "smsAllowed": true
                }
              ],
              "companyName": "Stephanie Swan"
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzE5OTA5OTUz",
              "address": {
                "street": "2105 W Falls Ave",
                "city": "Spokane",
                "province": "WA",
                "postalCode": "99201",
                "coordinates": {
                  "latitudeString": "47.6598127",
                  "longitudeString": "-117.4433081"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84NzgxNTQxOA==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/87815418",
              "jobNumber": 4297,
              "total": 0,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Kelly"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzEzNjYyMDQzOTc=",
            "title": "✂️🪜 Barden -S- 412 E 21st -1P 4h",
            "startAt": "2024-05-20T19:00:00Z",
            "endAt": "2024-05-20T21:30:00Z",
            "instructions": "12’ LADDER, EXTENDED SHEARS.\r\n Clear lilac from fence, remove stubs from birch, deadwood and slight thin of Japanese Maple, tie several flopping stems and light shear clients side only, arborvitae. Remove several water sprouts and slight thin, Magnolia in front of home. Remove several sprouts on lower branch of Norway Maple in front of home. Photos. IC.",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
                  "name": {
                    "full": "Jose Villa"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjAzMDkzMg==",
                  "name": {
                    "full": "Madeleine Hooker"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8xODg1MzMxNg==",
              "firstName": "Don",
              "lastName": "Barden",
              "emails": [
                {
                  "address": "Donald.barden@gmail.com",
                  "primary": true
                },
                {
                  "address": "donald.barden@gmail.com",
                  "primary": false
                }
              ],
              "phones": [
                {
                  "number": "(206) 450-3576",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5Lzg4NDM1MTY3",
              "address": {
                "street": "412 East 21st Avenue",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99203",
                "coordinates": {
                  "latitudeString": "47.6356317",
                  "longitudeString": "-117.4042357"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84Njg2NDEyMA==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/86864120",
              "jobNumber": 4264,
              "total": 480,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": "12’ LADDER, EXTENDED SHEARS. Clear lilac from fence, remove stubs from birch, deadwood and slight thin of Japanese Maple, tie several flopping stems and light shear clients side only, arborvitae. Remove several water sprouts and slight thin, Magnolia in front of home. Remove several sprouts on lower branch of Norway Maple in front of home. Photos. IC."
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Isaiah"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MDMwNTgxMDE=",
            "title": "🛻Clausen -NW- 3210 W Cora - 1S 3h",
            "startAt": "2024-05-21T14:30:00Z",
            "endAt": "2024-05-21T17:30:00Z",
            "instructions": "10’ LADDER\r\nSHORT + EXT. SHEARS\r\nTRUCK HAUL AWAY\r\n\r\nShaping arborvitae back to previous form, making certain tops are approximate to each other and cleared from home. Removing small volunteer cherry in grove of lilacs adjacent to E side fence. Photos. IC.",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
                  "name": {
                    "full": "Jose Villa"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8zNzkyOTU0Nw==",
              "firstName": "Jeff",
              "lastName": "Clausen",
              "emails": [
                {
                  "address": "clausenjeff@gmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "509-954-1987",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzQwODg5NzI4",
              "address": {
                "street": "3210 West Cora Avenue",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99205",
                "coordinates": {
                  "latitudeString": "47.6891598",
                  "longitudeString": "-117.4583996"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84OTI1ODE1Nw==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/89258157",
              "jobNumber": 4343,
              "total": 288,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Isaiah"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": "Kelly Corbin"
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MDcyNDM0Njc=",
            "title": "🔻Ringo -SC- 407 W 17th - 1R*/1C/2P 1d",
            "startAt": "2024-05-21T14:30:00Z",
            "endAt": "2024-05-21T23:30:00Z",
            "instructions": "👉🏼Ben\r\n🚚Felix\r\n\r\n[PROBABLY ADDING SOME WORK ON THE NEIGHBOR'S TREE OVER HOUSE]\r\n\r\nLarge Norway maple in back. Significant reduction cuts on southwest stem to provide more light and reduce leverage. Clean off extra sprouts near large stub. General pruning in tree to remove deadwood and open up more space. \r\nNeighboring Norway maple: Remove stem that is drooping over yard and near powerline. Knock down obvious interior large deadwood. \r\n\r\nA couple of training cuts on the Bradford pair in back. Shortening down, secondary leader and training for good future growth.\r\n\r\nRemove medium sized volunteer near Northeast corner of house interacting with Cedars\r\n\r\nPruning tuneup on dense area on East side. Removing obvious, deadwood, shortening and shaping we necessary. Remove 2 inch volunteer maple near Lilac. Keep as much density as possible for privacy. \r\n\r\nRemoval of volunteer pine interacting with Doug in back along concrete wall. \r\n\r\nBD",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkz",
                  "name": {
                    "full": "Ben Delaney"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvOTExMjIy",
                  "name": {
                    "full": "Felix Gayton"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNjAzMzI2",
                  "name": {
                    "full": "Zayren Bubb"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTgxMjY4OQ==",
                  "name": {
                    "full": "Omar Sierra"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8yOTQwMTEwMA==",
              "firstName": "Carolyn",
              "lastName": "Ringo",
              "emails": [
                {
                  "address": "ringo.carolyn@gmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 701-1318",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzMxNjg0Mzky",
              "address": {
                "street": "409 West 17th Avenue",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99203",
                "coordinates": {
                  "latitudeString": "47.639741",
                  "longitudeString": "-117.4172393"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84OTU1NTI0MQ==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/89555241",
              "jobNumber": 4351,
              "total": 2900,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Ben"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": "next-door"
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MTMwMTY0MjI=",
            "title": "Brandenburger -SE- 2084 S Parkwood Cr - 1P ?h",
            "startAt": "2024-05-21T14:30:00Z",
            "endAt": "2024-05-21T22:00:00Z",
            "instructions": "Smaller red maple in back yard needs to be pruned. Second mature red maple on HOA property leans over pond and may need some pruning as well. Please give bid for pruning larger maple for HOA team to review. ",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDU0NzYw",
                  "name": {
                    "full": "Krystn Parmley"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8xODg1MzE1OA==",
              "firstName": "Betty",
              "lastName": "Brandenburger",
              "emails": [
                {
                  "address": "bebespokane@gmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 448-9813",
                  "primary": true,
                  "smsAllowed": false
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzE5OTA5Mjcy",
              "address": {
                "street": "2084 S Parkwood Circle",
                "city": "Spokane",
                "province": "WA",
                "postalCode": "99223",
                "coordinates": {
                  "latitudeString": "47.6366485",
                  "longitudeString": "-117.3661726"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84OTkzOTgwOA==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/89939808",
              "jobNumber": 4360,
              "total": 1,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MDE3MjI2NDk=",
            "title": "Richardson -NWF- 10306 N Sundance Dr - 1C/1P 1d, 1G 1h + DT",
            "startAt": "2024-05-21T15:00:00Z",
            "endAt": "2024-05-21T23:30:00Z",
            "instructions": "🚒Tal+DT\r\nNorway maple in front.   \r\nPruning to remove deadwood and broken or damaged branches. Lift to comfortable hide over yard. Shorten side for separation.\r\n1C* 2.5h\r\n\r\nHawthorne. Minor thinning for good airflow and tree health. Separation from maple. Lift on driveway side and shape for good form.\r\n1P 1.5h\r\n\r\nTwo large cherry trees and back. Pruning for better long-term structure and durability.  \r\n1C 2h * 2\r\n\r\nApple in back, west side. \r\nOverhaul for more healthy form, fruit, production, sucker reduction. \r\n1P 2h\r\n\r\nApple in back, middle. Pruning for good form. \r\n1P 0.5h\r\n\r\nLarge Apple, east corner. Pruning overhaul for good form, fruit, production, tree health. Lowering tops and shortening around edges where necessary.  \r\n1P 4h\r\n\r\nCherry in back. \r\nRecommend removing section of tree growing up into large cherry, pruning out all deadwood. \r\n1P 0.75h\r\n\r\n1C/1P 1d\r\n\r\nBD",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
                  "name": {
                    "full": "Jose Villa"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjAzMDkzMg==",
                  "name": {
                    "full": "Madeleine Hooker"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDc1NDUx",
                  "name": {
                    "full": "Tal Weisenburger"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDU0NzYw",
                  "name": {
                    "full": "Krystn Parmley"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNjAzMzI2",
                  "name": {
                    "full": "Zayren Bubb"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8zNjg2Njk2NQ==",
              "firstName": "Edward",
              "lastName": "Richardson",
              "emails": [
                {
                  "address": "edward.richardson@usw.salvationarmy.org",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "509-638-5115",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": "Salvation Army"
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzkwODIxNTA5",
              "address": {
                "street": "10306 North Sundance Drive",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99208",
                "coordinates": {
                  "latitudeString": "47.75210120000001",
                  "longitudeString": "-117.4971032"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84OTE4Mjc4OA==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/89182788",
              "jobNumber": 4335,
              "total": 1875,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": "Multi-tree tuneup"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Ben"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzExMjUyNDMwOTk=",
            "title": "~🛻Curtis -SW- 2722 S Lincoln - 1P .5d (IA) ",
            "startAt": "2024-05-21T15:30:00Z",
            "endAt": "2024-05-21T19:30:00Z",
            "instructions": "🛻Pickup Truck clean\r\n10’ ladder smoke bush,apple,hawthorn,maple,oak. Apple thin deadwood and shape top, smoke bush subordinate lower branch slight thin and deadwood,maple,hawthorn and oak, slight clearance of sidewalk and buildings. Please see attached photos. IC",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE4",
                  "name": {
                    "full": "Anthony Morrow"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE2",
                  "name": {
                    "full": "Isaiah Crandall"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvOTExMjIy",
                  "name": {
                    "full": "Felix Gayton"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC82Njk3NDQ3OA==",
              "firstName": "Carl ",
              "lastName": "Curtis",
              "emails": [
                {
                  "address": "dukecurtis@comcast.net",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "509-720-4415 Curtis ",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzcyNDc3NDg0",
              "address": {
                "street": "2722 South Lincoln Street",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99203",
                "coordinates": {
                  "latitudeString": "47.629298",
                  "longitudeString": "-117.4253671"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi83MTM3MjgyOQ==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/71372829",
              "jobNumber": 3624,
              "total": 576,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": "10’ ladder smoke bush,apple,hawthorn,maple,oak. Apple thin deadwood and shape top, smoke bush subordinate lower branch slight thin and deadwood,maple,hawthorn and oak, slight clearance of sidewalk and buildings. Please see attached photos. IC"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Isaiah"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzE0MTg0MDg3NTA=",
            "title": "=WolffJr -C- 612 W Sumner - 2C/2P/2G 1d",
            "startAt": "2024-05-21T19:00:00Z",
            "endAt": "2024-05-21T23:00:00Z",
            "instructions": "Asses Property, continue work",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDU0NzYw",
                  "name": {
                    "full": "Krystn Parmley"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC8yOTY5MDc3Mg==",
              "firstName": "Fritz",
              "lastName": "Wolff Jr",
              "emails": [
                {
                  "address": "bradconom@gmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "509-844-1678 Brad Conom",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzMxOTk1OTIw",
              "address": {
                "street": "612 West Sumner Avenue",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99204",
                "coordinates": {
                  "latitudeString": "47.646974",
                  "longitudeString": "-117.4204796"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi8zNzA3OTI5OQ==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/37079299",
              "jobNumber": 1675,
              "total": 7395,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzEzOTY3Mzc3ODM=",
            "title": "Conom -PF- 414 E 1st Ave Post Falls - Full Crew for Days",
            "startAt": "2024-05-22T15:00:00Z",
            "endAt": "2024-05-23T00:00:00Z",
            "instructions": "👉 Felix\r\nFirewood dumped at: \r\n16816 E. Sprague.  Gate code 3636. There is two gates, one in the front of the yard and one of the side of the yard.\r\n--Extensive pruning of pines, maples, apples, etc. \r\n--many removals\r\nKelly",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTgxMjY4OQ==",
                  "name": {
                    "full": "Omar Sierra"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNTM2MDYx",
                  "name": {
                    "full": "Dario Ré"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDU0NzYw",
                  "name": {
                    "full": "Krystn Parmley"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvOTExMjIy",
                  "name": {
                    "full": "Felix Gayton"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkx",
                  "name": {
                    "full": "Kelly Chadwick"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjAzMDkzMg==",
                  "name": {
                    "full": "Madeleine Hooker"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNjAzMzI2",
                  "name": {
                    "full": "Zayren Bubb"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
                  "name": {
                    "full": "Jose Villa"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC80MTk2NzcxNQ==",
              "firstName": "Brad",
              "lastName": "Conom",
              "emails": [
                {
                  "address": "bradconom@gmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 844-1678",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": "Bkc Enterprises"
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzkwODAyNDcy",
              "address": {
                "street": "414 East 1st Avenue",
                "city": "Post Falls",
                "province": "Idaho",
                "postalCode": "83854",
                "coordinates": {
                  "latitudeString": "47.7053153",
                  "longitudeString": "-116.9448031"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84ODg1MTYyNw==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/88851627",
              "jobNumber": 4326,
              "total": 0,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzEzOTY3Mzc3ODQ=",
            "title": "Conom -PF- 414 E 1st Ave Post Falls - Full Crew for Days",
            "startAt": "2024-05-23T15:00:00Z",
            "endAt": "2024-05-24T00:00:00Z",
            "instructions": "👉 Felix\r\n🚚Zayren\r\nFirewood dumped at: \r\n16816 E. Sprague.  Gate code 3636. There is two gates, one in the front of the yard and one of the side of the yard.\r\n--Extensive pruning of pines, maples, apples, etc. \r\n--many removals\r\nKelly",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNDc1NDUx",
                  "name": {
                    "full": "Tal Weisenburger"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMjAzMDkzMg==",
                  "name": {
                    "full": "Madeleine Hooker"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMTgxMjY4OQ==",
                  "name": {
                    "full": "Omar Sierra"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvNjAzMzI2",
                  "name": {
                    "full": "Zayren Bubb"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY4Mzkx",
                  "name": {
                    "full": "Kelly Chadwick"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvOTExMjIy",
                  "name": {
                    "full": "Felix Gayton"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTA5",
                  "name": {
                    "full": "Jose Villa"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC80MTk2NzcxNQ==",
              "firstName": "Brad",
              "lastName": "Conom",
              "emails": [
                {
                  "address": "bradconom@gmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "(509) 844-1678",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": "Bkc Enterprises"
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzkwODAyNDcy",
              "address": {
                "street": "414 East 1st Avenue",
                "city": "Post Falls",
                "province": "Idaho",
                "postalCode": "83854",
                "coordinates": {
                  "latitudeString": "47.7053153",
                  "longitudeString": "-116.9448031"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84ODg1MTYyNw==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/88851627",
              "jobNumber": 4326,
              "total": 0,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        },
        {
          "node": {
            "id": "Z2lkOi8vSm9iYmVyL1Zpc2l0LzEzMjMwMzgzNDE=",
            "title": "Read -SE- 2531 S Magnolia -(IA)",
            "startAt": "2024-05-23T15:30:00Z",
            "endAt": "2024-05-23T18:30:00Z",
            "instructions": "TRUCK HAUL AWAY. Annual visit to check shrubs and smaller trees/arborvitae. IC",
            "assignedUsers": {
              "nodes": [
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE2",
                  "name": {
                    "full": "Isaiah Crandall"
                  }
                },
                {
                  "id": "Z2lkOi8vSm9iYmVyL1VzZXIvMzY5NTE4",
                  "name": {
                    "full": "Anthony Morrow"
                  }
                }
              ]
            },
            "client": {
              "id": "Z2lkOi8vSm9iYmVyL0NsaWVudC82NDc5NzQ1MA==",
              "firstName": "Megan",
              "lastName": "Read",
              "emails": [
                {
                  "address": "megan_hinnen@hotmail.com",
                  "primary": true
                }
              ],
              "phones": [
                {
                  "number": "509-999-5470",
                  "primary": true,
                  "smsAllowed": true
                }
              ],
              "companyName": null
            },
            "property": {
              "id": "Z2lkOi8vSm9iYmVyL1Byb3BlcnR5LzcwMTAxOTI2",
              "address": {
                "street": "2531 South Magnolia Street",
                "city": "Spokane",
                "province": "Washington",
                "postalCode": "99203",
                "coordinates": {
                  "latitudeString": "47.6306231",
                  "longitudeString": "-117.3828958"
                }
              }
            },
            "job": {
              "id": "Z2lkOi8vSm9iYmVyL0pvYi84NDM4NDkyNg==",
              "jobberWebUri": "https://secure.getjobber.com/work_orders/84384926",
              "jobNumber": 4195,
              "total": 288,
              "customFields": [
                {
                  "__typename": "CustomFieldText",
                  "label": "Job Information",
                  "valueText": "TRUCK HAUL AWAY. Annual visit to check shrubs and smaller trees/arborvitae. IC"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Misc",
                  "valueText": ""
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Bidder",
                  "valueText": "Isaiah"
                },
                {
                  "__typename": "CustomFieldText",
                  "label": "Referred By",
                  "valueText": ""
                }
              ]
            }
          }
        }
      ]
    }
  },
  "extensions": {
    "cost": {
      "requestedQueryCost": 9902,
      "actualQueryCost": 868,
      "throttleStatus": {
        "maximumAvailable": 10000,
        "currentlyAvailable": 9132,
        "restoreRate": 500
      }
    },
    "versioning": {
      "version": "2024-04-17"
    }
  }
};

//////////////////////////////////////////
// This is the transformation of the raw data from the API to the data 
// that the component will use:
//////////////////////////////////////////
const transformVisit = (visit: any): JobVisit => {
  const date = new Date(visit.node.startAt).toISOString().split('T')[0];
  const shortDate = new Date(visit.node.startAt).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', timeZone: 'UTC' });
  const startTime = visit.node.startAt ? visit.node.startAt.split('T')[1] : undefined;
  const endTime = visit.node.endAt ? visit.node.endAt.split('T')[1] : undefined;
  const dayName = new Date(visit.node.startAt).toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' });

  return {
    id: visit.node.id,
    jobNumber: visit.node.job.jobNumber,
    title: visit.node.title,
    date,
    dayName,
    shortDate,
    startTime, 
    endTime, 
    allDay: visit.node.allDay,
    location: {
      street: visit.node.property.address.street,
      city: visit.node.property.address.city,
      province: visit.node.property.address.province,
      postalCode: visit.node.property.address.postalCode,
      coordinates: {
        latitudeString: visit.node.property.address.coordinates.latitudeString,
        longitudeString: visit.node.property.address.coordinates.longitudeString
      }
    },
    instructions: visit.node.instructions,
    webUri: visit.node.job.jobberWebUri,
    client: {
      id: visit.node.client.id,
      fullName: visit.node.client.name,
      firstName: visit.node.client.firstName,
      lastName: visit.node.client.lastName,
      emails: visit.node.client.emails,
      phones: visit.node.client.phones,
      company: visit.node.client.companyName
    },
    total: visit.node.job.total,
    customFields: visit.node.job.customFields,
    // jobInfo: visit.node.job.customFields.find((field: any) => field.label === "Job Information")?.valueText,
    // bidder: visit.node.job.customFields.find((field: any) => field.label === "Bidder")?.valueText,
    assignedMembers: visit.node.assignedUsers.nodes.map((user: any) => ({
      id: user.id,
      firstName: user.name.full.split(' ')[0],
      lastName: user.name.full.split(' ')[1]
    })),
    confirmationStatus: "Unconfirmed"
  };
};

const transformDateRangeData = (data: any): ScheduleDocument => {
  const jobQueue: JobVisit[] = [];
  const scheduleDays: ScheduleDay[] = [];

  data.data.visits.edges.forEach((visit: any) => {
    const date = new Date(visit.node.startAt).toISOString().split('T')[0];
    const dateName = new Date(date).toLocaleDateString('en-US', { weekday: 'long', timeZone: 'UTC' });
    
    // Here's where we grab the visits on our user-selectable "Job Queue" day, which defaults to Sunday, and put them in the Job Queue.
    // TODO: NEEDS TO BE CHANGED TO THE DAY SELECTED BY THE USER
    if (dateName === APP_SETTINGS.jobQueueDay) {
      jobQueue.push(transformVisit(visit));
    }
    
    let scheduleDay = scheduleDays.find(day => day.date === date);
    if (!scheduleDay) {
      const shortDate = new Date(date).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', timeZone: 'UTC' });
      
      scheduleDay = {
        id: date,
        name: dateName,
        date: date,
        shortDate: shortDate,
        jobVisits: []
      };
      scheduleDays.push(scheduleDay);
    }
    scheduleDay.jobVisits?.push(transformVisit(visit));
  });

  return {
    id: 'SP-001',
    title: 'Schedule Document',
    date_created: new Date().toISOString(),
    date_modified: new Date().toISOString(),
    scheduleDays: scheduleDays,
    jobQueue: jobQueue
  };
};

const scheduleDocumentData = transformDateRangeData(visitsDATA);

export default scheduleDocumentData;