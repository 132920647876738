import React, { useEffect, useRef, useState, FC } from "react";
import { Outlet } from "react-router-dom";
import TopBar from "../TopBar";
// import Settings from "../Settings";
// import ScheduleDocument from "../ScheduleDocument";
import Sidebar from "../Sidebar";
import "./AppFrame.scss";

const savedSidebarState = localStorage.getItem('sidebarState');
const initialSidebarState = savedSidebarState ? JSON.parse(savedSidebarState) : true;

function AppFrame() {
  const [isSidebarOpen, setSidebarOpen] = useState(initialSidebarState);
  
  useEffect(() => {
    // Save the state to localStorage whenever it changes
    localStorage.setItem('sidebarState', JSON.stringify(isSidebarOpen));
  }, [isSidebarOpen]);

  return (
    <div className="app-frame">
      <TopBar
        toggleSidebar={() => setSidebarOpen(!isSidebarOpen)}
        isSidebarOpen={isSidebarOpen}
        isSplitFrameActive={false}
      />
      <div className="content-wrapper">
        <Sidebar isSidebarOpen={isSidebarOpen} />
        <div className="main-content">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AppFrame;
