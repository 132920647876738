import * as React from "react";
const SvgStatusImportant = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11m0-3.017A7.967 7.967 0 0 1 3.017 11 7.976 7.976 0 0 1 11 3.006 7.976 7.976 0 0 1 18.983 11 7.967 7.967 0 0 1 11 18.983m0-6.643c.73 0 1.154-.403 1.187-1.155l.174-3.866c.044-.795-.533-1.34-1.372-1.34s-1.405.534-1.361 1.329l.163 3.888c.044.74.468 1.144 1.209 1.144m0 3.583c.828 0 1.449-.501 1.449-1.285 0-.785-.61-1.286-1.449-1.286-.828 0-1.46.501-1.46 1.286 0 .784.643 1.285 1.46 1.285"
    />
  </svg>
);
export default SvgStatusImportant;
