import * as React from "react";
const SvgStatusPending = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11m0-3.017A7.967 7.967 0 0 1 3.017 11 7.976 7.976 0 0 1 11 3.006 7.976 7.976 0 0 1 18.983 11 7.967 7.967 0 0 1 11 18.983m.01-6.334c-.992 0-1.745-.64-1.745-1.649 0-.99.753-1.649 1.744-1.649 1.01 0 1.726.66 1.726 1.649 0 1.009-.716 1.649-1.726 1.649m-4.703 0c-.991 0-1.744-.64-1.744-1.649 0-.99.753-1.649 1.744-1.649 1.01 0 1.726.66 1.726 1.649 0 1.009-.716 1.649-1.726 1.649m9.404 0c-.991 0-1.744-.64-1.744-1.649 0-.99.753-1.649 1.744-1.649 1.01 0 1.726.66 1.726 1.649 0 1.009-.716 1.649-1.726 1.649"
    />
  </svg>
);
export default SvgStatusPending;
