import * as React from "react";
const SvgStatusImportantActive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <path
      fill="#FB3A25"
      fillRule="nonzero"
      d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11m.01-9.584c-.783 0-1.252-.436-1.295-1.22L9.54 7.101c-.044-.85.555-1.416 1.459-1.416.893 0 1.503.577 1.46 1.427l-.186 4.062c-.043.806-.49 1.242-1.263 1.242m0 3.8c-.881 0-1.557-.522-1.557-1.36 0-.84.676-1.373 1.558-1.373s1.546.534 1.546 1.372c0 .84-.664 1.362-1.546 1.362"
    />
  </svg>
);
export default SvgStatusImportantActive;
