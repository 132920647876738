import React, { useState, useRef, FC, useEffect } from "react";
import { AnimatePresence } from 'framer-motion';
import Modal from "components/Modal";
import APP_SETTINGS from "data/appSettings";
import { JobVisit, JobVisitConfirmationStatus } from "types";
import JobVisitConfirmationStatusSelector, { statusIcons } from "components/JobVisitConfirmationStatusSelector";
import JobHighlightMenu from "./JobHighlightMenu";
import JobDetailsView from "components/JobDetailsView";
import MapView from "components/MapView";
import RotatingIcon from "components/RotatingIcon";
import JobTimeInput from "./JobTimeInput";
import { AngleDown, CloseX } from "components/Icons";
import "./JobBlock.scss";

interface JobBlockProps {
  id: string;
  job: JobVisit;
  isActive: boolean;
  isSelected: boolean;
  onSelect: (id: string) => void;
}

const JobBlock: FC<JobBlockProps> = ({ id, job, isActive, isSelected, onSelect, children, ...props }) => {
  // State
  const [showHighlightMenu, setShowHighlightMenu] = useState(false);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [showJobDetailModal, setShowJobDetailModal] = useState(false);
  // const [mapCoords, setMapCoords] = useState({lat: 0, lng: 0});
  const [showMapModal, setShowMapModal] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const [confirmationStatus, setConfirmationStatus] = useState((job.confirmationStatus || "Open") as JobVisitConfirmationStatus);
  const [highlight, setHighlight] = useState(job.highlightId);
  const [startTime, setStartTime] = useState(job.startTime);
  const [endTime, setEndTime] = useState(job.endTime);
  const [blockActive, setBlockActive] = useState(isActive || false);
  
  // Refs
  const modalTrigger = useRef<HTMLElement>(null);
  const highlightToggle = useRef<HTMLButtonElement>(null);
  const jobVisitConfirmationStatusSelectorRef = useRef<HTMLDivElement>(null);

  // Handlers
  const handleBlockActive = (e: React.MouseEvent) => {
    console.log('>>> Job Block: ', id);
    onSelect(id);
  };
  const handleSetStatus = (confirmationStatus: JobVisitConfirmationStatus) => {
    // if (confirmationStatus === "Confirmed") {
    //   setShowConfirmDialog(true);
    // } else {
    setConfirmationStatus(confirmationStatus);
    console.log(`💾SELECTED STATUS: jobId:${job.id} confirmationStatus:${confirmationStatus}`);
    setShowStatusMenu(false);
    // }
  };
  const handleSetHighlight = (highlightId: string) => {
    setHighlight(highlightId);
    setShowHighlightMenu(false);
    console.log(
      `💾SELECTED HIGHLIGHT: jobId:${job.id} highlightId:${highlightId}`,
    );
  };
  // const handleConfirmYes = () => {
  //   sendConfirmationNote();
  //   setShowConfirmDialog(false);
  //   setConfirmationStatus("Confirmed");
  //   console.log(`💾SELECTED STATUS: jobId:${job.id} confirmationStatus:Confirmed`);
  //   setShowStatusMenu(false);
  // };
  // const handleConfirmNo = () => {
  //   setShowConfirmDialog(false);
  //   setConfirmationStatus("Confirmed");
  //   console.log(`💾SELECTED STATUS: jobId:${job.id} confirmationStatus:Confirmed`);
  //   setShowStatusMenu(false);
  // };
  const handleJobDetailModalOpen = (e: React.MouseEvent) => {
    if (e.altKey) {
      window.open(job.webUri, '_blank');
      return;
    }

    setShowJobDetailModal(true);
  };
  const handleJobDetailModalClose = () => {
    setShowJobDetailModal(false);
  };
  const handleMapModalOpen = (e: React.MouseEvent) => {
    setShowMapModal(true);
  };
  const handleMapModalClose = () => {
    setShowMapModal(false);
  };

  // Other functions
  // const sendConfirmationNote = () => {
  //   console.log(`Send confirmation note to Jobber! jobId:${job.id}`);
  //   // Add your code to send the confirmation note here
  // };

  // Effect
  useEffect(() => {
    setBlockActive(isActive || showStatusMenu || showHighlightMenu);
  }, [isActive, showStatusMenu, showHighlightMenu]);

  return (
    <div
      onClick={handleBlockActive}
      className={`job-block highlight-${highlight} ${blockActive ? "active" : ""} ${isSelected ? "selected" : ""}`}
    >
      <div className="job-block-line">
        <JobVisitConfirmationStatusSelector
          ref={jobVisitConfirmationStatusSelectorRef}
          status={confirmationStatus}
          hideText={true}
          onOpen={() => setShowStatusMenu(true)}
          onSelect={handleSetStatus}
        />

        <div className="job-title-wrap">
          <div className="job-title">
            <span
              className="client-last-name"
              ref={modalTrigger}
              onClick={(e) => { handleJobDetailModalOpen(e); }}
            >
              {job.client?.lastName}
            </span>
            <span>-</span>
            <span className="map-link"
              ref={modalTrigger}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleMapModalOpen(e);
              }}
            >
              {job.location?.street}
            </span>
            {job.customFields && (
              <span className="custom-fields">
                {job.customFields
                  .filter(
                    (field) => field.__typename === "CustomFieldText"
                      && APP_SETTINGS.jobTitleIncludedCustomFields.some(
                        (item) => item.label === field.label
                      )
                      && field.valueText !== ""
                  )
                  .map((field, i) => (
                    <React.Fragment key={i}>
                      <span>-</span>
                      <span className="field">{field.valueText}</span>
                    </React.Fragment>
                  ))}
              </span>
            )
            }
          </div>
        </div>

        <div className="time-inputs">
          <JobTimeInput
            initialTime={startTime}
            onTimeChange={setStartTime}
          />
          <span>-</span>
          <JobTimeInput
            initialTime={endTime}
            onTimeChange={setEndTime}
          />
        </div>

        <button className={`job-highlight-toggle ${showHighlightMenu ? "menu-active" : ""}`}
          ref={highlightToggle}
          onClick={() => setShowHighlightMenu(!showHighlightMenu)}>
          <RotatingIcon rotate={showHighlightMenu} degrees={180} icon={<AngleDown/>} />
        </button>
      </div>

      <div className="job-block-children">
        {children}
      </div>

      {showHighlightMenu && (
        <JobHighlightMenu
          onSelect={handleSetHighlight}
          onClose={() => setShowHighlightMenu(false)}
          trigger={highlightToggle}
        />
      )}

      <AnimatePresence>
        {/* Confirm Confirmation Dialog - ON HOLD */}
        {/* {showConfirmDialog && (
          <Popover
            pointTo={jobVisitConfirmationStatusSelectorRef}
            height="auto"
            position="right"
            closeButton={false}
            clickOutsideToClose={false}
          >
            <div className="dialog add-note-confirmation">
              <h2>Add Confirmation Note?</h2>
              <p>If you choose 'Yes', a note will be added to <a href={job.webUri} target="_blank">this job</a> in Jobber containing:
                <span>👍🏼CONFIRMED for [DATE], [TIME]</span>
              </p>
              <div className="button-group">
                <button onClick={handleConfirmNo}>No</button>
                <button onClick={handleConfirmYes} className="primary">Yes</button>
              </div>
            </div>
          </Popover>
        )} */}

        {/* Job Details Modal */}
        {showJobDetailModal && (
          <Modal
            key="job-details-modal"
            closeButton={false}
            clickOutsideToClose={true}
            onClose={handleJobDetailModalClose}
            escToClose={true}
          >
            <JobDetailsView job={job} />
          </Modal>
        )}
        {/* Map Modal */}
        {showMapModal && (
          <Modal
            key="map-modal"
            closeButton={false}
            clickOutsideToClose={true}
            styles={{ padding: '0px' }}
            onClose={handleMapModalClose}
            escToClose={true}
          >
            <div className="map-view-modal-container">
              <MapView position={{
                lat: parseFloat(job.location?.coordinates?.latitudeString || "0"),
                lng: parseFloat(job.location?.coordinates?.longitudeString || "0"),
              }} />
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </div>
  );
};

export default JobBlock;
