import React, { useState, useRef, useEffect, FC } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Ellipsis, Archive, Trashcan } from "components/Icons";
import ScheduleItemMenu from "./ScheduleItemMenu";

interface ScheduleListItemProps {
  schedule: {
    id: string;
    title: string;
  };
}

const ScheduleListItem: FC<ScheduleListItemProps> = ({ schedule }) => {
  const [showScheduleItemMenu, setShowScheduleItemMenu] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [isActive, setIsActive] = useState(false);
  const menuButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleScheduleItemMenu = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (menuButtonRef.current) {
      const rect = menuButtonRef.current.getBoundingClientRect();
      setMenuPosition({ top: rect.top, left: rect.right });        
    };
    setShowScheduleItemMenu(!showScheduleItemMenu); 
  };

  useEffect(() => {
    setIsActive(showScheduleItemMenu);
  }, [showScheduleItemMenu]);

  return (
    <div key={schedule.id} className="schedule-item">
      <Link to={`/schedule/${schedule.id}`}>
        <span>{schedule.title}</span>
      </Link>
      <div className="actions">
        <button
          className={`${isActive ? 'active' : ''}`}
          ref={menuButtonRef}
          onClick={(e) => {
            handleScheduleItemMenu(e);
          }}
          >
          <Ellipsis/>
        </button>
        <button
          onClick={(e) => {
            alert('Archive coming soon!');
            console.log('archive clicked');
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <Archive/>
        </button>
      </div>

      {showScheduleItemMenu && ReactDOM.createPortal(
        <ScheduleItemMenu
          schedule={schedule}
          position={{top: menuPosition.top, left: menuPosition.left-22 }}
          trigger={menuButtonRef}
          onClose={() => setShowScheduleItemMenu(false)}
        />,
        document.body
      )}
    </div>
  ); 
};

export default ScheduleListItem;