import React, {FC, useEffect, useState} from 'react';
import SelectMenu, { SelectMenuOption, getSelectMenuOption } from './SelectMenu';

export type MonthlyRecurrenceType = 
| "Exact Date" 
| "Week & Day";

const monthlyRecurrenceOptions = [
  {value: "Exact Date", label: "Exact Date"},
  {value: "Week & Day", label: "Week & Day"}
];

interface MonthlyRecurrenceSelectMenuProps {
  selected?: MonthlyRecurrenceType;
  onChange: (selectedOption:any) => void;
  selectProps?: any;
}

const MonthlyRecurrenceSelectMenu: FC<MonthlyRecurrenceSelectMenuProps> = ({
  selected,
  onChange,
  selectProps 
}) => {
  const [selectedOption, setSelectedOption] = useState(getSelectMenuOption(selected, monthlyRecurrenceOptions)); 
  
  useEffect(() => {
    setSelectedOption(getSelectMenuOption(selected, monthlyRecurrenceOptions));
  }, [selected]);

  useEffect(() => {
    onChange(selectedOption);
  }, [selectedOption]);

  const handleChange = (selectedOption:any) => {
    setSelectedOption(getSelectMenuOption(selectedOption, monthlyRecurrenceOptions));
  };
  
  return (
    <SelectMenu 
      options={monthlyRecurrenceOptions} 
      value={selectedOption}
      selectedOption={selectedOption}
      onChange={onChange}
      placeholder="Monthly Recurrence"
      className="monthly-recurrence-trigger"
      {...selectProps}
    />
  );
};
  

export default MonthlyRecurrenceSelectMenu;