import React, {FC, useEffect, useState, useRef} from 'react';
import SelectMenu, { SelectMenuOption, getSelectMenuOption } from './SelectMenu';
import { MonthlyRecurrenceType } from './MonthlyRecurrenceSelectMenu';
import { SelectInstance } from 'react-select';

// Types
export type MonthlyDateType = "1st" | "2nd" | "3rd" | "4th" | "5th" | "6th" | "7th" | "8th" | "9th" | "10th" | "11th" | "12th" | "13th" | "14th" | "15th" | "16th" | "17th" | "18th" | "19th" | "20th" | "21st" | "22nd" | "23rd" | "24th" | "25th" | "26th" | "27th" | "28th" | "29th" | "30th" | "31st";
export type MonthlyWeekType = "First" | "Second" | "Third" | "Fourth" | "Last";
export type MonthlyDayOfWeekType = "Sunday" | "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday";

// Arrays for the SelectMenu options
const MonthlyDays = ["1st","2nd","3rd","4th","5th","6th","7th","8th","9th","10th","11th","12th","13th","14th","15th","16th","17th","18th","19th","20th","21st","22nd","23rd","24th","25th","26th","27th","28th","29th","30th","31st"];
const MonthlyWeeks = ["First","Second","Third","Fourth","Last"];
const MonthlyDayOfWeeks = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
const monthlyDateOptions: SelectMenuOption[] = MonthlyDays.map(day => ({ value: day, label: day }));
const monthlyWeekOptions: SelectMenuOption[] = MonthlyWeeks.map(week => ({ value: week, label: week }));
const monthlyDayOfWeekOptions: SelectMenuOption[] = MonthlyDayOfWeeks.map(day => ({ value: day, label: day }));

interface MonthlyRecurrenceDetailSelectorMenuProps {
  monthlyRecurrenceType?: MonthlyRecurrenceType;
  monthlyDate?: MonthlyDateType;
  monthlyWeek?: MonthlyWeekType;
  monthlyDayOfWeek?: MonthlyDayOfWeekType;
  onChange?: (selectedOption:any) => void;
  onMonthlyRecurrenceTypeChange?: (selectedOption:any) => void;
  onMonthlyDateChange?: (selectedOption:any) => void;
  onMonthlyWeekChange?: (selectedOption:any) => void;
  onMonthlyDayOfWeekChange?: (selectedOption:any) => void;
  selectProps?: any;
}

/////////////////////////////////////////////////////
// The MonthlyRecurrenceDetailSelector component
const MonthlyRecurrenceDetailSelector: FC<MonthlyRecurrenceDetailSelectorMenuProps> = ({
  monthlyRecurrenceType,
  monthlyDate,
  monthlyWeek,
  monthlyDayOfWeek,
  onChange,
  onMonthlyRecurrenceTypeChange,
  onMonthlyDateChange,
  onMonthlyWeekChange,
  onMonthlyDayOfWeekChange,
  selectProps
}) => {  
  const [monthlyRecurrenceTypeValue, setMonthlyRecurrenceTypeValue] = useState(monthlyRecurrenceType);
  const [monthlyDateValue, setMonthlyDateValue] = useState((monthlyDate && getSelectMenuOption(monthlyDate, monthlyDateOptions)));
  const [monthlyWeekValue, setMonthlyWeekValue] = useState((monthlyWeek && getSelectMenuOption(monthlyWeek, monthlyWeekOptions)));
  const [monthlyDayOfWeekValue, setMonthlyDayOfWeekValue] = useState((monthlyDayOfWeek && getSelectMenuOption(monthlyDayOfWeek, monthlyDayOfWeekOptions)));
  
  // Refs
  const dayOfWeekRef = useRef<SelectInstance>(null);

  // Handlers
  const handleMonthlyDateChange = (selectedOption:SelectMenuOption|null) => {
    setMonthlyDateValue(selectedOption);
  };
  const handleMonthlyWeekChange = (selectedOption: SelectMenuOption) => {
    setMonthlyWeekValue(selectedOption);
    const dayOfWeekMenu = dayOfWeekRef.current;
    dayOfWeekMenu && dayOfWeekMenu.focus();  
  };
  const handleMonthlyDayOfWeekChange = (selectedOption: SelectMenuOption) => {
    setMonthlyDayOfWeekValue(selectedOption);
  };

  // If external props change, update the internal state
  useEffect(() => {
    setMonthlyRecurrenceTypeValue(monthlyRecurrenceType);
  }, [monthlyRecurrenceType]);
  useEffect(() => {
    setMonthlyDateValue((monthlyDate && getSelectMenuOption(monthlyDate, monthlyDateOptions)));
  }, [monthlyDate]);
  useEffect(() => {
    setMonthlyWeekValue((monthlyWeek && getSelectMenuOption(monthlyWeek, monthlyWeekOptions)));
  }, [monthlyWeek]);
  useEffect(() => {
    setMonthlyDayOfWeekValue((monthlyDayOfWeek && getSelectMenuOption(monthlyDayOfWeek, monthlyDayOfWeekOptions)));
  }, [monthlyDayOfWeek]);

  // State changes trigger the onChange callbacks
  useEffect(() => {
    onMonthlyRecurrenceTypeChange && onMonthlyRecurrenceTypeChange(monthlyRecurrenceTypeValue);
  }, [monthlyRecurrenceTypeValue]);
  useEffect(() => {
    onMonthlyDateChange && onMonthlyDateChange(monthlyDateValue);
  }, [monthlyDateValue]);
  useEffect(() => {
    onMonthlyWeekChange && onMonthlyWeekChange(monthlyWeekValue);
  }, [monthlyWeekValue]);
  useEffect(() => {
    onMonthlyDayOfWeekChange && onMonthlyDayOfWeekChange(monthlyDayOfWeekValue);
  }, [monthlyDayOfWeekValue]);
  
  useEffect(() => {
    onChange && onChange({
      monthlyRecurrenceType: monthlyRecurrenceTypeValue,
      monthlyDate: monthlyDateValue,
      monthlyWeek: monthlyWeekValue,
      monthlyDayOfWeek: monthlyDayOfWeekValue,
    });
  }, [monthlyRecurrenceType, monthlyDate, monthlyWeek, monthlyDayOfWeek]);

  return (
    <>
      {monthlyRecurrenceTypeValue && (
        <p className="descriptive-text">Repeat every month on the:</p>
      )}
      {monthlyRecurrenceTypeValue === "Exact Date" && (
        <SelectMenu 
          options={monthlyDateOptions} 
          value={monthlyDateValue}
          onChange={handleMonthlyDateChange}
          placeholder="Select Date..."
          {...selectProps}
        />
      )}
      {monthlyRecurrenceTypeValue === "Week & Day" && (
        <div 
          className="week&day-group"
          style={{display: 'flex', gap: '10px'}}
        >
          <SelectMenu 
            options={monthlyWeekOptions} 
            value={monthlyWeekValue}
            onChange={handleMonthlyWeekChange}
            placeholder="Week..."
            className={`${monthlyRecurrenceTypeValue.toLowerCase().replace(/ & /g, '&')}-week-trigger`}
            styles={{
              container: (baseStyles:any) => ({
                ...baseStyles,
                flexGrow: 0,
              })
            }}
            {...selectProps}
          />
          <SelectMenu
            ref={dayOfWeekRef}
            options={monthlyDayOfWeekOptions} 
            value={monthlyDayOfWeekValue}
            onChange={handleMonthlyDayOfWeekChange}
            placeholder="Weekday..."
            className={`${monthlyRecurrenceTypeValue.toLowerCase().replace(/ & /g, '&')}-dayofweek-trigger`}
            styles={{
              container: (baseStyles:any) => ({
                ...baseStyles,
                flexGrow: 1,
              })
            }}
            {...selectProps}
          />
        </div>
     )}
    </>
  );
};

export default MonthlyRecurrenceDetailSelector;