const teamMemberHighlights: { id: string; name: string }[] = [
  { id: "1", name: "Climber, level 1" },
  { id: "2", name: "Climber, level 2" },
  { id: "5", name: "Ground Support" },
  { id: "3", name: "Pruner" },
  { id: "4", name: "Shearer" },
  { id: "6", name: "Remover" },
  { id: "7", name: "Feller" },
  { id: "8", name: "Equipment Operator" },
];

export default teamMemberHighlights;