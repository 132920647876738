import React, { FC, useRef } from "react";
import jobHighlights from "data/jobHighlightsData";
import useClickOutside from 'hooks/useClickOutside';

// Define the JobHighlightMenuProps type
interface JobHighlightMenuProps {
  onSelect: (highlight: string) => void;
  onClose: () => void;
  trigger: React.RefObject<HTMLElement>;
}

export const JobHighlightMenu: FC<JobHighlightMenuProps> = ({
  onSelect,
  trigger,
  onClose
}) => {
  const menuRef = useRef(null);
  useClickOutside(menuRef, onClose, trigger);

  return (
    <ul ref={menuRef} className="job-highlight-menu">
      {jobHighlights.map((h) => (
        <li
          className={`highlight-${h.id}`}
          key={h.id}
          onClick={() => onSelect(h.id)}
        >
          <span>{h.name}</span>
          <div className="highlight-box"></div>
        </li>
      ))}
    </ul>
  );
}

export default JobHighlightMenu;