import React, { useState, FC, useEffect } from "react";
import { JobVisit, ScheduleDay, TeamMember } from "types";
import APP_SETTINGS from "data/appSettings";
import { FoldCaret } from "components/Icons";
import JobBlock from "components/JobBlock";
import RotatingIcon from "components/RotatingIcon";
import WeatherIcon from "components/WeatherIcon";
import TeamMemberBlock from "components/TeamMemberBlock";

import "./ScheduleDocument.scss";

////////////////////////////////////////
// TEMPORARY DATA // 
// import JobsData from "data/jobsData";
// import ScheduleDocumentData from "data/scheduleDocumentData";
import TeamMembersData from "data/teamMembersData";
import JobVisitsByDateRange from "data/jobVisitsByDateRange";

const ScheduleDocumentData = JobVisitsByDateRange;
////////////////////////////////////////

// Setting predefined group visibility states
interface GroupFoldState {
  teamMembers: boolean;
  jobQueue: boolean;
  [key: string]: boolean; // This allows for dynamic keys
}
const initialFoldState: GroupFoldState = {
  teamMembers: false,
  jobQueue: true,
};

interface ScheduleDocumentProps {
  title?: string | null;
  dates?: [string, string];
}

const ScheduleDocument: FC<ScheduleDocumentProps> = () => {
  // State
  const [foldState, setFoldState] = useState<GroupFoldState>(initialFoldState);
  const [selectedBlock, setSelectedBlock] = useState<string | null>(null);
  const [selectedBlocks, setSelectedBlocks] = useState<string[]>([]);
  const [isMultiSelecting, setIsMultiSelecting] = useState<boolean>(false);
  const [isAltOrOptionPressed, setIsAltOrOptionPressed] = useState<boolean>(false);
  const [initialSelectionIndex, setInitialSelectionIndex] = useState<number | null>(null);
  const [jobQueue, setJobQueue] = useState<JobVisit[] | null>(null);

  const currentDate = new Date();

  // Handlers
  const outerDragRef = React.useRef<HTMLDivElement>(null);
  const handleDragJobStart = (e: MouseEvent | TouchEvent | PointerEvent, info: { point: { x: number, y: number } }, job: JobVisit) => {
    console.log("dragging job", job.id);
    // e.dataTransfer.setData("jobId", job.id);
  };
  const handleDragTeamMemberStart = (e: MouseEvent | TouchEvent | PointerEvent, info: { point: { x: number, y: number } }, member: TeamMember) => {
    console.log("dragging teammember", member.id);
    // e.dataTransfer.setData("jobId", job.id);
  };
  const handleJobBlockActive = (id: string) => {
    console.log('Job Block Active:', id);
    setSelectedBlock(id);
  };
  const handleTeamMemberBlockActive = (id: string) => {
    console.log('Team Member Block Active:', id);
    setSelectedBlock(id);
  };

  // Effects
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowUp':
        case 'ArrowDown':
          event.preventDefault(); // Prevent page scrolling
          if (event.altKey) {
            handleReorder(event.key === 'ArrowUp' ? 'up' : 'down');
          } else {
            handleSelectionChange(event.key === 'ArrowUp' ? 'up' : 'down');
          }
          break;
        case 'Shift':
          setIsMultiSelecting(true);
          break;
        case 'Alt': // OPTION key on Mac
        case 'Option':
          setIsAltOrOptionPressed(true);
          break;
        case 'Escape':
          setSelectedBlock(null);
          // setSelectedBlocks([]);
          break;
        default:
          break;
      }
    };
  
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setIsMultiSelecting(false);
      }
      if (event.key === 'Alt' || event.key === 'Option') {
        setIsAltOrOptionPressed(false);
      }
    };
  
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
  
    // Clean up the event listeners
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [selectedBlocks, isMultiSelecting]);
  useEffect(() => {
    console.log('ScheduleDocument: selected Block:', selectedBlock);
  }, [selectedBlock]);
  
  const handleSelectionChange = (direction: 'up' | 'down') => {
    // FIRST VERSION: 
    // MIGHT KEEP SOME OF THIS BUT MIGHT NOT:
    /*
    const allBlocks = getAllBlocks();
    if (allBlocks.length === 0) return;
  
    let currentIndex = allBlocks.findIndex(block => block.id === selectedBlock);
  
    if (currentIndex === -1) {
      currentIndex = direction === 'up' ? allBlocks.length : -1;
    }
  
    let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;
  
    if (newIndex < 0) newIndex = allBlocks.length - 1;
    if (newIndex >= allBlocks.length) newIndex = 0;
  
    const newActiveBlock = allBlocks[newIndex].id;
  
    if (isMultiSelecting) {
      if (initialSelectionIndex === null) {
        setInitialSelectionIndex(currentIndex);
      }
  
      let newSelectedBlocks;
      if (newIndex < initialSelectionIndex!) {
        newSelectedBlocks = allBlocks.slice(newIndex, initialSelectionIndex! + 1).map(block => block.id);
      } else {
        newSelectedBlocks = allBlocks.slice(initialSelectionIndex!, newIndex + 1).map(block => block.id);
      }
  
      setSelectedBlocks(newSelectedBlocks);
  
      if (newSelectedBlocks.length === 1) {
        setInitialSelectionIndex(newIndex);
      }
    } else {
      setSelectedBlocks([newActiveBlock]);
      setInitialSelectionIndex(currentIndex);
    }
  
    setSelectedBlock(newActiveBlock);
    */
  };

  const handleReorder = (direction: 'up' | 'down') => {
    // FIRST VERSION: 
    // MIGHT KEEP SOME OF THIS BUT MIGHT NOT:
    // console.log('Reordering blocks');
    // const allBlocks = getAllBlocks(); // Implement this to get all the blocks in order
    // const currentIndex = allBlocks.findIndex(block => block.id === selectedBlock);
    // let newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

    // // Ensure the new index is within bounds
    // if (newIndex < 0 || newIndex >= allBlocks.length) return;

    // // Swap blocks in the array
    // [allBlocks[currentIndex], allBlocks[newIndex]] = [allBlocks[newIndex], allBlocks[currentIndex]];

    // // Update state with the new order
    // updateBlocks(allBlocks); // Implement this to update the state with the new order
    // setSelectedBlocks([allBlocks[newIndex].id]);
    // setSelectedBlock(allBlocks[newIndex].id);
  };

  // Helper functions
  const getAllBlocks = (): Array<JobVisit | TeamMember> => {
    // Flatten all blocks (jobs and team members) in the desired order
    const blocks: Array<JobVisit | TeamMember> = [];

    ScheduleDocumentData.scheduleDays.forEach(day => {
      if (day.jobVisits) {
        blocks.push(...day.jobVisits);
        day.jobVisits.forEach(job => {
          if (job.assignedMembers) {
            blocks.push(...job.assignedMembers);
          }
        });
      }
      if (day.unassignedMembers) {
        blocks.push(...day.unassignedMembers);
      }
    });

    if (ScheduleDocumentData.jobQueue) {
      blocks.push(...ScheduleDocumentData.jobQueue);
    }

    return blocks;
  };

  const updateBlocks = (newBlocks: Array<JobVisit | TeamMember>) => {
    // Create a copy of the original data to avoid direct mutation
    const updatedData = { ...ScheduleDocumentData };

    // Iterate over each day and update job visits and unassigned members
    updatedData.scheduleDays = updatedData.scheduleDays.map(day => {
      const updatedDay = { ...day };

      // Update job visits
      updatedDay.jobVisits = newBlocks.filter(
        (block): block is JobVisit => 'assignedMembers' in block && block.assignedDate === day.date
      );

      // Update unassigned members
      updatedDay.unassignedMembers = newBlocks.filter(
        (block): block is TeamMember => 'assignedMembers' in block && block.assignedDate === day.date
      );

      // Update assigned members for each job
      updatedDay.jobVisits = updatedDay.jobVisits.map(job => {
        const updatedJob = { ...job };
        updatedJob.assignedMembers = newBlocks.filter(
          (block): block is TeamMember => 'assignedJobId' in block && block.assignedJobId === updatedJob.id
        );
        return updatedJob;
      });

      return updatedDay;
    });

    // Update job queue separately
    updatedData.jobQueue = newBlocks.filter((block): block is JobVisit => (block as JobVisit).assignedDate === null);

    // Set the updated data back to state or context
    // setScheduleDocumentData(updatedData); // Uncomment and use the appropriate state updater
  };


  // Generic Toggling function for sections:
  const toggleGroupFold = (section: keyof GroupFoldState) => {
    setFoldState((prevFold) => ({
      ...prevFold,
      [section]: !prevFold[section],
    }));
  };

  // Adding a key to the initialFoldState for each date in the scheduleDateRange, 
  // ..and for each "unassigned" group within those dates
  ScheduleDocumentData.scheduleDays.forEach((day) => {
    initialFoldState[day.date] = true;
    initialFoldState[day.date + "unassignedGroup"] = false;
  });

  return (
    <div className="schedule-document" ref={outerDragRef}>
      <section className="document-head">
        {/* TODO: Convert h1 to editableText: */}
        <h1>Scheduling 3/4-7</h1>
        <button onClick={() => { alert("edit schedule settings") }}>Settings</button>
      </section>

      {/* JOB QUEUE */}
      <section className="job-queue">
        <header onClick={() => toggleGroupFold("jobQueue")}>
          <div className="toggle">
            <RotatingIcon rotate={foldState.jobQueue} icon={<FoldCaret />} />
          </div>
          <h2>Job Queue</h2>
        </header>
        <div className={`section-inner ${foldState["jobQueue"] ? 'open' : 'closed'}`}>
          {ScheduleDocumentData.jobQueue?.map((job) => (
            <JobBlock
              id={job.id}
              key={`job-${job.id}`}
              job={job}
              isActive={job.id === selectedBlock}
              isSelected={selectedBlocks.includes(job.id)}
              onSelect={() => handleJobBlockActive(job.id)}
            />
          ))}
        </div>
      </section>

      {/* DAYS */}
      {ScheduleDocumentData.scheduleDays
        .filter((day: ScheduleDay) => day.name !== APP_SETTINGS.jobQueueDay)
        .map((day: ScheduleDay) => (
          <section className="schedule-day" key={day.date}>
            <header onClick={() => toggleGroupFold(day.date)}>
              <div className="toggle">
                <RotatingIcon rotate={foldState[day.date]} icon={<FoldCaret />} />
              </div>              
              <h2>
                <a href={`https://secure.getjobber.com/calendar#day/${day.date.replace(/-/g, "/")}`} target="_blank">{day.name} {day.shortDate}</a>
                {day.date > currentDate.toISOString().split('T')[0] ? <WeatherIcon date={day.date} linkOut={true} postalCode={APP_SETTINGS.weatherPostalCode} /> : null}              </h2>
            </header>

            <div className={`section-inner ${foldState[day.date] ? 'open' : 'closed'}`}>
              {/* Unassigned Group: */}
              <div
                key={day.date + "unassignedGroup"}
                className={`block-group unassigned-members 
                  ${foldState[day.date + "unassignedGroup"] ? 'open' : 'closed'}
                  ${(day.unassignedMembers?.length || 0) === 0 ? 'empty' : ''}
                `}
              >
                <div className="block-group-header" onClick={() => toggleGroupFold(day.date + "unassignedGroup")}>
                  <div className="toggle">
                    <RotatingIcon rotate={foldState[day.date + "unassignedGroup"]} icon={<FoldCaret />} />
                  </div>
                  <div className="block-group-name">Unassigned {(day.unassignedMembers?.length || 0) === 0 ? '(none)' : ''}</div >
                </div>
                <div className="block-group-inner">
                  {(day.unassignedMembers?.length || 0) === 0 ? (
                    <span className="empty-msg">Move members here to unassign them</span>
                  ) : (
                    day.unassignedMembers?.map((member) => (
                      <TeamMemberBlock
                        id={member.id}
                        key={member.id}
                        teamMember={member}
                        isActive={member.id === selectedBlock}
                        isSelected={selectedBlocks.includes(member.id)}
                        onSelect={(id) => handleTeamMemberBlockActive(member.id)}
                      />
                    ))
                  )}
                </div>
              </div>

              {/* Job Visits: */}
              {day.jobVisits?.map((job: JobVisit) => {
                const uniqueId = `${day.id}-${job.id}`;
                return <JobBlock
                  id={job.id}
                  key={`job-${job.id}`}
                  job={job}
                  isActive={uniqueId === selectedBlock}
                  isSelected={selectedBlocks.includes(uniqueId)}
                  onSelect={() => handleJobBlockActive(uniqueId)}
                >
                  {job.assignedMembers?.map((member: TeamMember) => {
                    const uniqueId = `${job.id}-${member.id}`;
                    return <TeamMemberBlock
                      id={uniqueId}
                      key={member.id}
                      teamMember={member}
                      isActive={uniqueId === selectedBlock}
                      isSelected={selectedBlocks.includes(uniqueId)}
                      onSelect={(id) => handleTeamMemberBlockActive(uniqueId)}
                    />
                  })}
                </JobBlock>
              })}
            </div>
          </section>
        ))}
    </div>
  );
};

export default ScheduleDocument;
