import React, {FC, useState} from 'react';
import SelectMenu from './SelectMenu';

export type EventTypeType = 
  | "Arrives Late" 
  | "Leaves Early" 
  | "Personal Appointment" 
  | "Not Working" 
  | "Vacation";

const eventTypes: any[] = [
  {name: "Arrives Late", color: "#FF7F00"},
  {name: "Leaves Early", color: "#FF7F00"},
  {name: "Personal Appointment", color: "#9D4DF2"},
  {name: "Not Working", color: "#A87360"},
  {name: "Vacation", color: "#2BAA2E"},
];

const eventTypeoptions = eventTypes.map((eventType) => ({
  value: eventType.name,
  label: eventType.name,
  color: eventType.color,
}));

interface EventTypeSelectMenuProps {
  selected?: EventTypeType;
  onChange: (selectedOption:any) => void;
  selectProps?: any;
}
  
const EventTypeSelectMenu: FC<EventTypeSelectMenuProps> = ({
  selected, 
  onChange, 
  selectProps 
}) => {
  let selectedOption;
  if (selected) {
    const foundOption = eventTypeoptions.find((option) => option.value === selected);
    selectedOption = foundOption ? foundOption : null;
  }
  return (
    <SelectMenu 
      selectedOption={selectedOption}
      value={selectedOption}
      placeholder="Event Type"
      isSearchable={false}
      options={eventTypeoptions} 
      onChange={onChange}
      {...selectProps}
    />
  );
};

export default EventTypeSelectMenu;