import React, { useEffect, useRef, useState, FC } from "react";
import { Chips, Chip } from "@jobber/components/Chips";
// import { AnimatePresence } from 'framer-motion';
// import Modal from 'components/Modal';
import { JobVisit, JobVisitConfirmationStatus, PhoneNumber, Email, TeamMember, JobNote } from "types";
import JobVisitConfirmationStatusSelector, { statusIcons } from "components/JobVisitConfirmationStatusSelector";
import { PlusCircle, LinkOut } from "components/Icons";
import "./JobDetailsView.scss";
import JobTimeInput from "components/JobBlock/JobTimeInput";

interface JobDetailsViewProps {
  job: JobVisit;
  className?: string;
  onAnimationEnd?: React.AnimationEventHandler<HTMLDivElement>;
}

const JobDetailsView: FC<JobDetailsViewProps> = ({ job, className, onAnimationEnd }) => {
  // State
  const [startTime, setStartTime] = useState(job.startTime);
  const [endTime, setEndTime] = useState(job.endTime);
  const [showStatusMenu, setShowStatusMenu] = useState(false);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSendingConfirmedNote, setIsSendingConfirmedNote] = useState(false);
  const [confirmationStatus, setConfirmationStatus] = useState((job.confirmationStatus || "Unconfirmed") as JobVisitConfirmationStatus);

  // Refs
  const jobVisitConfirmationStatusSelectorRef = useRef<HTMLDivElement>(null);
  const confirmDialogRef = useRef(null);

  // Handlers
  const handleSetStatus = (confirmationStatus: JobVisitConfirmationStatus) => {
    // if (confirmationStatus === "Confirmed") {
    //   setIsConfirmDialogOpen(true);
    // } else {
    setConfirmationStatus(confirmationStatus);
    console.log(`💾SELECTED STATUS: jobId:${job.id} confirmationStatus:${confirmationStatus}`);
    setShowStatusMenu(false);
    // }
  };

  ////////////////////////////////////////////
  // HOLDING OFF ON CONFIRMATION NOTE FOR NOW
  //    I think we should do this differently.
  ////////////////////////////////////////////
  // const handleConfirmYes = () => {
  //   setIsSendingConfirmedNote(true);
  //   sendConfirmationNote();
  //   setIsConfirmDialogOpen(false);
  //   setConfirmationStatus("Confirmed");
  //   console.log(`💾SELECTED STATUS: jobId:${job.id} confirmationStatus:Confirmed`);
  //   setShowStatusMenu(false);
  // };
  // const handleConfirmNo = () => {
  //   setIsSendingConfirmedNote(false);
  //   setIsConfirmDialogOpen(false);
  //   setConfirmationStatus("Confirmed");
  //   console.log(`💾SELECTED STATUS: jobId:${job.id} confirmationStatus:Confirmed`);
  //   setShowStatusMenu(false);
  // };
  // const sendConfirmationNote = () => {
  //   console.log("Sending confirmation note...");
  //   // Add your code to send the confirmation note here
  // };


  return (
    <div
      className={`job-details-view ${className ?? ''}`}
      onAnimationEnd={onAnimationEnd}
    >

      <header>
        <a className="job-number jobber-link" href={`${job.webUri}`} target="_blank">Job #{job.jobNumber}<LinkOut /></a>
        <div className={`job-title highlight-${job.highlightId}`}>{job.title}</div>
      </header>

      <div className="container">
        <div className="column left">
          <div className="date-time">
            <div className="date">{job.dayName}, {job.shortDate}</div>
            <div className="time-inputs">
              <JobTimeInput
                initialTime={startTime}
                onTimeChange={setStartTime}
              />
              <span>-</span>
              <JobTimeInput
                initialTime={endTime}
                onTimeChange={setEndTime}
              />
            </div>
          </div>

          <div className="status-selector-wrap">
            <JobVisitConfirmationStatusSelector
              ref={jobVisitConfirmationStatusSelectorRef}
              status={confirmationStatus}
              onOpen={() => setShowStatusMenu(true)}
              onClose={() => setShowStatusMenu(false)}
              onSelect={handleSetStatus}
            />
          </div>

          <div className="fields-and-instructions">
            <ul className="fields">
              {/* TODO: These are all currently hard-coded but we really need to loop through 
                  all the *user-selected* [SETTINGS] custom fields and output lable and value for each */}
              <li><span className="label">Workcode:</span> {job.workCode}</li>
              <li><span className="label">Total:</span> ${job.total}</li>
              {job.jobInfo && <li><span className="label">Job Info:</span> {job.jobInfo}</li>}
              <li><span className="label">Bidder: </span> {job.bidder}</li>
            </ul>

            <div className="instructions">
              <span className="label">Instructions:</span>
              Large crabapple in front. Pruning to control overgrowth and thin where appropriate.
              <br />2P 4h<br /><br />Thundercloud Plum in back. Remove broken branches and tuneup for health and wellness.
              <br />1P 5h<br /><br />Pruning to improve shape and structure of arbs on west side.
              <br />2P 4h
              {/* {job.instructions} */}
            </div>
          </div>
        </div>

        <div className="column right">
          <div className="client-details">
            <div className="client-name">{job.client?.firstName} {job.client?.lastName}</div>
            <div className="client-location">
              <p>
                <a href={`https://www.google.com/maps/search/?api=1&query=${job.location?.street},${job.location?.city},${job.location?.province},${job.location?.postalCode}`} target="_blank" rel="noopener noreferrer">
                  {job.location?.street}<br />
                  {job.location?.city}, {job.location?.province} {job.location?.postalCode}
                </a>
              </p>
            </div>
            <div className="client-contact-info">
              {job.client?.phones?.map((phone: PhoneNumber, index: number) => (
                <p key={index}><a href={`tel:${phone.number}`}>{phone.number}</a></p>
              ))}
              {job.client?.emails?.map((email: Email, index: number) => (
                <p key={index}><a href={`mailto:${email.address}`}>{email.address}</a></p>
              ))}
            </div>
          </div>

          <div className="assigned-team-members">
            <span className="label">Assigned Team Members:</span>
            {job.assignedMembers?.length ? (
              <ul>
                {job.assignedMembers.map((member: TeamMember, index: number) => (
                  <li className="team-member" key={index}>
                    <div className="avatar"><img src={`/images/${member.firstName.toLowerCase()}.png`} /></div>
                    <span className="name">{member.firstName}</span>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="secondary">No team members assigned</p>
            )}
            {/* TODO: Adding members, add later: */}
            {/* <button className="add-team-member"><PlusCircle /></button> */}
          </div>

          <div className="notes">
            <span className="label">Job Notes:</span>
            {job.notes?.length ? (
              <ol>
                {job.notes?.map((note: JobNote, index: number) => (
                  <li className="note" key={index}>
                    <span className="message">{note.message}</span>
                  </li>
                ))}
              </ol>
            ) : (
              <p className="secondary">No notes</p>
            )}
          </div>
        </div>
      </div>

      {/* <AnimatePresence>
        {isConfirmDialogOpen && (
          <Popover
            ref={confirmDialogRef}
            key="confirm-dialog"
            pointTo={jobVisitConfirmationStatusSelectorRef}
            height="auto"
            position="right"
            closeButton={false}
            clickOutsideToClose={false}
          >
            <div className="dialog add-note-confirmation">
              <h2>Add Confirmation Note?</h2>
              <p>If you choose 'Yes', a note will be added to this job in Jobber containing:
                <span>👍🏼CONFIRMED for [DATE], [TIME]</span>
              </p>
              <div className="button-group">
                <button onClick={handleConfirmNo}>No</button>
                <button onClick={handleConfirmYes} className="primary">Yes</button>
              </div>
            </div>
          </Popover>
        )}
      </AnimatePresence> */}
    </div>
  );
};

export default JobDetailsView;