import React, { FC, forwardRef, useRef, useState } from "react";
import Select, { components, DropdownIndicatorProps, ClearIndicatorProps, SelectInstance } from 'react-select'
import { AngleDown, X } from "components/Icons";
import './SelectMenu.scss';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <AngleDown/>
    </components.DropdownIndicator>
  );
};
const ClearIndicator = (props: ClearIndicatorProps) => (
  <components.ClearIndicator {...props}>
    <X/>
  </components.ClearIndicator>
);

// Helper function to get the selected option
export const getSelectMenuOption = (value: string|undefined, options: SelectMenuOption[]) => {
  if (!value) return;
  const option = options.find(option => option.value === value) || null;
  return option;
}

export interface SelectMenuOption {
  value: any;
  label: string;
}

interface SelectMenuProps {
  options: SelectMenuOption[];
  selectedOption?: any;
  onChange: (selectedOption:any) => void;
  styles?: any;
  placeholder?: string;
  selectProps?: any;
}

//////////////////////////////////////////////
// Component: SelectMenu
const SelectMenu: FC<SelectMenuProps> = forwardRef<SelectInstance, SelectMenuProps>(({options, selectedOption, onChange, styles, placeholder, ...selectProps }, ref) => {
  const defaultStyles = {
    control: (baseStyles:any) => ({
      ...baseStyles,
      backgroundColor: '#EFEFEF',
      borderRadius: '5px',
      borderColor: '#D8D8D8',
      fontWeight: '700',
      fontSize: '16px',
      // '&:hover': {
      //   borderColor: 'red',
      // },
    }),
    valueContainer: (baseStyles:any) => ({
      ...baseStyles,
      paddingRight: '0px',
    }),
    singleValue: (baseStyles:any, {data}:{data:any}) => ({
      ...baseStyles,
      color: data.color ? data.color : '#333',
    }),
    placeholder: (baseStyles:any) => ({
      ...baseStyles,
      color: '#BDBDBD',
      fontWeight: '700',
      fontSize: '16px',
    }),
    menu: (baseStyles:any) => ({
      ...baseStyles,
      borderRadius: '5px',
      backgroundColor: '#efefef',
      boxShadow: '0 0 6px 0 rgba(0,0,0,0.30)',
    }),
    menuList: (baseStyles:any) => ({
      ...baseStyles,
      padding: '0',
      borderRadius: '5px',
    }),
    option: (baseStyles:any, { data, isDisabled, isFocused, isSelected }: { data: any, isDisabled: boolean, isFocused: boolean, isSelected: boolean }) => ({
      ...baseStyles,
      color: isFocused ? 'white' : 'inherit',
      fontSize: '16px',
      fontWeight: 'bold',
      backgroundColor: isFocused ? (data.color || '#0087FF') : 'transparent',
      cursor: isDisabled ? 'not-allowed' : 'default',
      borderRadius: '5px',
      padding: '11px 12px',
    }),
    dropdownIndicator: (baseStyles:any) => ({
      ...baseStyles,
      paddingLeft: '2px',
      fontSize: '16px',
      color: '#BDBDBD',
    }),
    clearIndicator: (baseStyles:any) => ({
      ...baseStyles,
      fontSize: '20px',
      color: '#BDBDBD',
    }),
  };

  const mergedStyles = {
    ...defaultStyles,
    ...styles
  };

  return (
    <Select 
      ref={ref}
      defaultValue={selectedOption}
      components={{
        DropdownIndicator, 
        IndicatorSeparator: null,
        ClearIndicator,
      }}
      placeholder={placeholder ? placeholder : "Select..."}
      classNamePrefix="selectmenu"
      options={options} 
      blurInputOnSelect={true}
      isClearable={true}
      isSearchable={false}
      menuPlacement="auto"
      onChange={onChange}
      {...selectProps}
      styles={mergedStyles}
    />
  )
});

export default SelectMenu;