import React, { useState, useRef, useEffect, FC } from "react";
import { v4 as uuidv4 } from 'uuid';
import { TeamMember } from "types";
import Popover from "components/Popover";
import { AngleDown } from "components/Icons";
import RotatingIcon from "components/RotatingIcon";
import TeamMemberHighlightMenu from "./TeamMemberHighlightMenu";
import "./TeamMemberBlock.scss";

interface TeamMemberBlockProps {
  id: string;
  teamMember: TeamMember;
  isActive: boolean;
  isSelected: boolean;
  onSelect?: (id: string) => void;
  onActiveChange?: (id: string) => void;
}

const TeamMemberBlock: FC<TeamMemberBlockProps> = ({ id, teamMember, isActive, isSelected, onSelect, ...props }) => {
  // State
  const [showHighlightMenu, setShowHighlightMenu] = useState(false);
  const [highlight, setHighlight] = useState(teamMember.highlightId);
  const [editableText, setEditableText] = useState(false);
  const [displayName, setDisplayName] = useState(teamMember.firstName);
  const [blockActive, setBlockActive] = useState(isActive || false);
  const [inputWidth, setInputWidth] = useState("0px");

  const origName = teamMember.firstName;
  // I like it better with just first name, but maybe we could include the option for last name initial or full last name...
  // const origName = teamMember.firstName + (teamMember.lastName ? " " + teamMember.lastName.charAt(0) + "." : "");

  // Refs
  const mainRef = useRef<HTMLDivElement>(null);
  const menuToggleRef = useRef(null);
  const nameInputRef = useRef<HTMLInputElement>(null);

  // Handlers
  const handleBlockActive = (id:string) => {
    console.log('>>> TeamMember Block: ', id);
    onSelect ? onSelect(id) : console.log('No onSelect handler provided');
  };
  
  const handleSetHighlight = (highlightId: string) => {
    setHighlight(highlightId);
    setShowHighlightMenu(false);
  };
  const handleDisplayNameChange = () => {
    setEditableText(false);
    const newName = displayName;
    if (newName !== origName) {
      console.log(`💾EDITED TEAM MEMBER DISPLAY NAME: teamMemberId:${teamMember.id} displayName:${newName}`);
    }
  };

  // Effects
  useEffect(() => {
    setBlockActive(isActive || showHighlightMenu);
  }, [isActive, showHighlightMenu]);
  useEffect(() => {
    if (editableText && nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [editableText]);
  useEffect(() => {
    setInputWidth(`${displayName.length + 1}ch`);

    let newName = displayName.startsWith(origName) ? origName + displayName.slice(origName.length) : origName;

    if (newName !== displayName) {
      setDisplayName(newName);
    }
  }, [displayName]);
  useEffect(() => {
    // console.log(
    //   `💾SELECTED TEAM MEMBER HIGHLIGHT: teamMemberId:${teamMember.id} highlightId:${highlight}`,
    // );
  }, [highlight]);


  return (
    <div
      ref={mainRef}
      className={`team-member-block highlight-${highlight} ${blockActive ? "active" : ""} ${isSelected ? "selected" : ""} ${editableText ? "editable" : ""}`}
      onClick={e => {
        e.stopPropagation();
        handleBlockActive(`${id}`);
      }}
    >
      {editableText ? (
        <div className="input-wrap">
          <input
            style={{ width: inputWidth }}
            ref={nameInputRef}
            type="text"
            value={displayName}
            onChange={e => setDisplayName(e.target.value)}
            onBlur={handleDisplayNameChange}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                e.preventDefault();
                (e.target as HTMLElement).blur();
              }
            }}
          />
        </div>
      ) : (
        <span className="team-member-name" onClick={() => {
          setEditableText(true);
        }}>
          {displayName}
        </span>
      )}
      <button
        ref={menuToggleRef}
        className={`team-member-highlight-toggle ${showHighlightMenu ? "menu-active" : ""}`}
        onClick={(e) => {
          e.preventDefault();
          // e.stopPropagation();

          setShowHighlightMenu(!showHighlightMenu);
        }}
      >
        <RotatingIcon rotate={showHighlightMenu} degrees={180} icon={<AngleDown />} />
      </button>

      {showHighlightMenu && (
        <TeamMemberHighlightMenu
          onSelect={handleSetHighlight}
          onClose={() => setShowHighlightMenu(false)}
          trigger={menuToggleRef}
        />
      )}

    </div>
  );
}

export default TeamMemberBlock;
