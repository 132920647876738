import React, { FC, useEffect, useRef } from "react";
import teamMemberHighlights from "data/teamMemberHighlightsData";
import useClickOutside from 'hooks/useClickOutside';

// Define the TeamMemberHighlightMenuProps type
interface TeamMemberHighlightMenuProps {
  onOpen?: () => void;
  onClose?: () => void;
  onSelect: (highlight: string) => void;
  trigger: React.RefObject<HTMLElement>;
}

export const TeamMemberHighlightMenu: FC<TeamMemberHighlightMenuProps> = ({
  onOpen = () => {},
  onClose = () => {},
  onSelect = () => {},
  trigger,
}) => {
  const menuRef = useRef(null);
  useClickOutside(menuRef, onClose, trigger);

  useEffect(() => {
    onOpen();
  }, []);

  return (
    <ul ref={menuRef} className="team-member-highlight-menu">
      {teamMemberHighlights.map((h) => (
        <li
          className={`highlight-${h.id}`}
          key={h.id}
          onClick={() => onSelect(h.id)}
        >
          <div className="highlight-box"></div>
          <span>{h.name}</span>
        </li>
      ))}
    </ul>
  );
}

export default TeamMemberHighlightMenu;