import * as React from "react";
const SvgStatusPendingActive = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="1em" height="1em" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M11 22c6.045 0 11-4.966 11-11 0-6.045-4.955-11-11-11S0 4.955 0 11c0 6.034 4.955 11 11 11"
      />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M11.01 12.649c-.992 0-1.745-.64-1.745-1.649 0-.99.753-1.649 1.744-1.649 1.01 0 1.726.66 1.726 1.649 0 1.009-.716 1.649-1.726 1.649M6.307 12.649c-.991 0-1.744-.64-1.744-1.649 0-.99.753-1.649 1.744-1.649 1.01 0 1.726.66 1.726 1.649 0 1.009-.716 1.649-1.726 1.649M15.711 12.649c-.991 0-1.744-.64-1.744-1.649 0-.99.753-1.649 1.744-1.649 1.01 0 1.726.66 1.726 1.649 0 1.009-.716 1.649-1.726 1.649"
      />
    </g>
  </svg>
);
export default SvgStatusPendingActive;
