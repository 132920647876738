import React, {FC, useState} from 'react';
import SelectMenu, { SelectMenuOption, getSelectMenuOption } from './SelectMenu';
import { get } from 'http';

export type RecurrenceType = 
// | "One-Off Event"
| "Every Week"
| "Every Other Week"
| "Every Month";

const recurrenceOptions = [
  {value: "Every Week", label: "Every Week"},
  {value: "Every Other Week", label: "Every Other Week"},
  {value: "Every Month", label: "Every Month"},
];

interface RecurrenceSelectMenuProps {
  selected?: RecurrenceType;
  onChange: (selectedOption:any) => void;
  selectProps?: any;
}

const RecurrenceSelectMenu: FC<RecurrenceSelectMenuProps> = ({
  selected, 
  onChange, 
  selectProps
}) => {
  const selectedOption = selected ? recurrenceOptions.find(option => option.value === selected) || null : undefined;
  
  return (
    <SelectMenu 
      options={recurrenceOptions} 
      value={selectedOption}
      selectedOption={selectedOption}
      placeholder="Recurrence"
      onChange={onChange}
      {...selectProps}
    />
  );
};
  

export default RecurrenceSelectMenu;